import '../style/CourseItem.css';
import RateAndPrice from '../../../components/RateAndPrice/RateAndPrice';
import {useNavigate} from 'react-router-dom';
import {CourseItemProps} from '../interfaces/ICourses';
import {Enums} from '../../../helpers/Enums';
import {colors} from '../../../constants/colors';
import {truncate} from '../../../helpers/truncate';
const CourseItem = ({course}: CourseItemProps) => {
  const navigate = useNavigate();

  function handleClick() {
    course.is_enrolled
      ? navigate(`/lessons/${course?.id}`)
      : navigate(`/courses/${course?.id}`);
  }

  return (
    <div className="course-item d-flex row" onClick={handleClick}>
      <span
        className={`course-type ${
          course?.is_live === 1 ? 'live-course-type' : 'recorded-course-type'
        }`}>
        {course?.is_live === 1 ? Enums.Live : Enums.Recorded}
      </span>
      <h4>{truncate(course.title, 25)} </h4>
      <div className="course-image col-3">
        {course.image || course.thumbnail ? (
          <img src={course?.thumbnail ? course?.thumbnail : course?.image} />
        ) : (
          <img
            src={'/logo.png'}
            alt="course-img"
            style={{
              width: '8rem',
              padding: '2rem',
              margin: 'auto',
              display: 'block',
              height: 'auto',
            }}
          />
        )}
      </div>
      <div className="course-details col-7">
        {course.is_enrolled ? (
          <div className="col-md-2 course-price">
            <div className="discount-price">Enrolled</div>
          </div>
        ) : course.free ? (
          <div className="col-md-2 course-price">
            <div className="discount-price">Free</div>
          </div>
        ) : (
          <div className="col-md-2 course-price">
            <div className="discount-price">
              {course?.price} {course?.user_currency}
            </div>
          </div>
        )}

        {course?.is_limited == 1 &&
          !course?.is_enrolled &&
          course?.is_live == 1 && (
            <span
              style={{
                background: colors.chamThirdColor,
                padding: '5px 10px',
                borderRadius: '10px',
                color: '#fff',
                fontSize: '13px',
              }}>
              {course?.has_reach_limit == 1
                ? `Sold out seats: ${course?.remaining_number} left of ${course?.limited_number} seats`
                : `Limited seats: ${course?.remaining_number} left of ${course?.limited_number} seats`}
            </span>
          )}

        <RateAndPrice
          ratings={course.ratings}
          ratings_count={course.ratings_count}
          subscribers={course.subscribers}
        />

        {/*<p>{course.description}</p>*/}
        <p className="course-provider">By {course?.provider_data?.name}</p>
      </div>
    </div>
  );
};

export default CourseItem;
