import {Col, Container, Row} from 'react-bootstrap';
import './style/CourseDetails.css';
import CourseSections from './CourseSections';
import CourseFeedback from './CourseFeedback';
import {CourseType, FeedBack} from './interfaces/ICourses';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {getCourseDetails, getFeedBacks} from './Courses.service';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import CourseCard from './components/CourseCard';

const CourseDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {id} = useParams();
  const [item, setItem] = useState<CourseType | undefined>();
  const [feedBacks, setFeedBacks] = useState<FeedBack[]>([]);

  useEffect(() => {
    getData();
    getFeedBacksFN();
  }, []);

  const getFeedBacksFN = async () => {
    let result = await getFeedBacks(id!);
    if (result?.response) {
      setFeedBacks(result.response.data);
    }
  };

  const getData = async () => {
    dispatch(setLoading(true));
    let result = await getCourseDetails(id!);

    if (result?.response) {
      let record = result.response
      if( record && record.not_available){
        navigate('/not-available')
      }else{
        if( result.response.data.is_enrolled ){
          navigate(`/lessons/${id}`)
        }
        setItem(result.response.data);
      }
      
    }
    
    
    dispatch(setLoading(false));
  };
  return (
    <div>
      <div className="header d-flex">
        <Container className="d-flex align-items-center">
          <Row className="d-flex align-items-center">
            <Col xs={6} className="w-100">
              <div className="d-flex justify-content-center">
                <h3 style={{
                      fontSize: '32px',
                      fontWeight: '600'
                }}>{item?.title}</h3>
                
              </div>
              <div 
              style={{
                fontSize: '16px',
                
              }}
              className={`d-flex align-items-center px-3  py-2 course-custom-price-label gap-2 ${item?.price ? `blue-bg` : `blue-bg` }`}>
                <img src="/solar_dollar-linear.svg" />
                {!item?.is_enrolled && item?.price ? `${item?.price} ${item?.user_currency}` : 'Free'}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {item && (
        <Container>
          <Row className="d-flex justify-content-between">
            <Col xs={{span: 12, order: 2}} md={{span: 7, order: 1}}>
              <CourseSections course={item} />
              {item.rating_stars && (
                <CourseFeedback
                  ratings={item.ratings}
                  rating_stars={item.rating_stars}
                  feedback={feedBacks}
                />
              )}
            </Col>
            {item.includes && (
              <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}}>
                {/*<CourseCard course={item} />*/}
                <CourseCard course={item} />
              </Col>
            )}
          </Row>
        </Container>
      )}
    </div>
  );
};

export default CourseDetails;
