// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getAuth} from 'firebase/auth';
import {getDatabase} from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyASCbEH83P9EbeYHrL7ZHtyjqOpmYkOXQE",
  authDomain: "mata3-921bf.firebaseapp.com",
  databaseURL: "https://mata3-921bf-default-rtdb.firebaseio.com",
  projectId: "mata3-921bf",
  storageBucket: "mata3-921bf.firebasestorage.app",
  messagingSenderId: "674161393653",
  appId: "1:674161393653:web:cc41abb8880fc25137aabc",
  measurementId: "G-N46TSC7NNY"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const auth = getAuth(app);
