import { FaCopy, FaFacebook, FaInstagram, FaLinkedin, FaRegCopy, FaShareAlt, FaTwitter, FaUserPlus, FaWhatsapp } from "react-icons/fa"
import ActionButton from "../../UI/action-button/ActionButton"
import { Dropdown } from "react-bootstrap"
import "./ShareContent.css"
import toast, {Toaster} from 'react-hot-toast';
import { useEffect, useState } from "react";

const ShareContent = ({url,hasInvite=false,copyLink=false,hasIcon=false,text='',fullWidth=false}) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleWidthChange = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addListener(handleWidthChange);
    
    return () => {
      mediaQuery.removeListener(handleWidthChange);
    };
  }, []);

    // Get the current URL
    var currentUrl = window.location.href;

    // Encode the URL
    var encodedUrl = encodeURIComponent(currentUrl);

    const handleCopyClick = () => {
      toast.success('copied');
      // Create a temporary input element to copy the value
      const tempInput = document.createElement('input');
      tempInput.value = url ?? encodedUrl;
      document.body.appendChild(tempInput);
  
      // Select and copy the value
      tempInput.select();
      document.execCommand('copy');
      
      // Remove the temporary input
      document.body.removeChild(tempInput);
    };

    const handleCopyLink = () => {
     
    // Get the current URL
    var url = window.location.href;
    
    // Copy the URL to clipboard
    navigator.clipboard.writeText(url)
    .then(function() {
        console.log('URL copied to clipboard');
        toast.success('copied');
    })
    .catch(function(err) {
        console.error('Failed to copy: ', err);
        // Provide fallback for browsers not supporting clipboard API
    });
    };

    return (
     <>

     <div style={{ background: '#f8f8f8' }} className="rounded p-4 mb-4">
        <div className="font-20 font-weight-600 text-gray-300 mb-2">Share Link</div>
        <div className="font-14 font-weight-400 text-gray-400 mb-3">Help your friends learn by sharing this link.</div>
        <div className="d-flex gap-3 cursor-pointer">
          
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${url ?? encodedUrl}`} target="_blank">
            <img src="/share-facebook.png" alt="share-img" />
          </a>

          <a href={`https://twitter.com/share?url=${url ?? encodedUrl}`} target="_blank">
            <img src="/share-x.png" alt="share-img" />
          </a>

          <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${url ?? encodedUrl}`} target="_blank">
            <img src="/share-linkedin.png" alt="share-img" />
          </a>

        

          {isMobile ? (
          <a href={`whatsapp://send?text=${url ?? encodedUrl}`} target="_blank">
              <img src="/share-whatsapp.png" alt="share-img" />
          </a>
          ) : (
            ''
          )}
          
          
          
          
        </div>

        { (hasInvite || copyLink) ? 
        <div className="d-flex gap-3 align-items-center mt-4">
          <input readOnly type="text" value={url} className="flex-1 rounded border-0 p-2" style={{ background: '#0092C81A' }} />
          <div 
          className="text-white cursor-pointer rounded py-2 px-3 font-16 font-weight-500"
          style={{ background:'var(--blue)' }}
          onClick={handleCopyClick}
          >
            Copy Link
          </div>
        </div>
        : '' }

     </div>
     
     </>   
    )
}


export default ShareContent