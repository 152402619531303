export const getChatDomain = () => {
  
    let chatDomainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' || hostName == 'webapp-dev.mataa3.com' ){
        chatDomainName = 'DEV'
    }

    else if( hostName == 'webapp-test.mataa3.com' ){
        chatDomainName = 'TEST'
    }

    else if( hostName == 'webapp-main.mataa3.com' || hostName == 'mataa3.com' ){
        chatDomainName = 'Chats'
    }
    
    else{
        chatDomainName = 'DEV'
    }
    
    
    return chatDomainName
  
};
