import {Col, Container, Row} from 'react-bootstrap';
import ShoppingCartItem from './components/ShopingCartItem';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/ShoppingCart.module.css';
import {useAppSelector} from '../../hooks/hooks';
import {useNavigate} from 'react-router';
import {colors} from '../../constants/colors';
import {useState , useEffect} from 'react';
import { getUserCurrency } from '../../helpers/getUserCurrency';
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';

const ShoppingCart = () => {
  const {t} = useTranslation();
  const userCountry = getUserCurrency()
  const navigate = useNavigate();
  const cartData = useAppSelector(state => state.cart);
  const [checkoutIsDisabled, setCheckoutIsDisabled] = useState(false);
  const checkCartData = () => {
    
    const hasSoldOutProduct = cartData.data.some(product => product.is_soldout == 1);
    const hasUnavailabelProduct = cartData.data.some(product => product.is_not_available == 1);
    if (hasSoldOutProduct || hasUnavailabelProduct ) {
      console.log('At least one product is sold out.');
      setCheckoutIsDisabled(true)
    } else {
      console.log('No product is sold out.');
    }
  }
  useEffect(() => {
    checkCartData()
  }, );
  return (

    <>
    <div className="contact-header d-flex justify-content-center align-items-center">
      <Container>
        <Row className="d-flex justify-content-center">
          <Col xs={6} className="d-flex justify-content-center align-items-center">
            <div className="breadcrumb d-flex justify-content-center">
              <h3 className="breadcrumb-header">Cart</h3>
            </div>
          </Col>

        </Row>
      </Container>
    </div>
    <Container
      fluid
      className={`${classes.ShoppingCartContainer}`}>
      <Row className='justify-content-center'>
        <h3 className={`main-title p-0 mt-3 ${classes.ShoppingCartTitle} text-center`}>Shopping Cart</h3>
        <Col md={8}>
          
          <div className={`w-100 ${classes.CartDetails} `}>
          { checkoutIsDisabled ?
            <Alert  variant={'primary'} className='d-flex align-items-center'>
          <img src="/icons/info.png" className='mx-1' style={{ width: '20px', height: '20px' , cursor: 'pointer' }} /> You have some products sold out or uncovered in your country, please remove it to be able to proceed to checkout
          </Alert>
            : '' }
            <div className="Items">
              {cartData.data.map(i => (
                <ShoppingCartItem data={i} />
              ))}
            </div>
          </div>
        </Col>

        <Col md={8} className={`${classes.CheckoutDetails} p-md-0`}>
          {/*
          <div>
            <h6>Number Of Products:</h6>
            <span>{cartData.totalProducts}</span>
          </div>
          */}

          

          <div className='d-flex align-items-center justify-content-between'>
            <h6>Total:</h6>
            <span>{cartData.totalPrice} {userCountry}</span>
          </div>
          <div className="mt-4">
            
            <MainButton
              disabled={checkoutIsDisabled}
              text={{ img: "/arrow-right.svg", label: "Checkout", alt: "Icon" }}
              style={{
                backgroundColor:
                  (cartData.data.length != 0 && checkoutIsDisabled == false ) ? colors.chamThirdColor : colors.gray,
                borderColor:
                  (cartData.data.length != 0 && checkoutIsDisabled == false ) ? colors.chamThirdColor : colors.gray,
              }}
              onClick={() => {
                if (cartData.data.length != 0) navigate(`/checkout?total=${cartData.totalPrice}`);
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default ShoppingCart;
