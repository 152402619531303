import {Container, Row} from 'react-bootstrap';
import {CourseItemProps} from './interfaces/ICourses';
import RateStars from '../../UI/rate-stars/RateStars';
import SourceViews from '../../UI/source-views/SourceViews';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Collapse} from 'react-bootstrap';
import {FaChevronDown, FaChevronUp, FaCircle, FaMinus, FaPlus} from 'react-icons/fa';
import './style/CourseSections.css';
import moment from 'moment';
import ShareIcon from '../../components/share-icon/ShareIcon';
import { getDynamicLinkDomain } from '../../helpers/getDynamicLinkDomain';
import ShareContent from '../../components/share-content/ShareContent';

const CourseSections = ({course}: CourseItemProps) => {
  const [collapsed, setCollapsed] = useState<number | null>(null);

  const changeCollapeHandler = (index: number) => {
    if (collapsed == index) {
      setCollapsed(null);
    } else {
      setCollapsed(index);
    }
  };

  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setShowAll(true);
  };

  const handleShowLess = () => {
    setShowAll(false);
  };

  useEffect(() => {
    createDynamicLink();
  }, []);

  const [url, setUrl] = useState('https://mataa3.page.link');

  const createDynamicLink = async () => {
   
    const requestBody = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://mataa3.page.link',
        link: `${getDynamicLinkDomain()}/?type=Course&model_id=${course.id}`,
        androidInfo: {
          androidPackageName: 'com.together.mata3',
        },
        iosInfo: {
          iosBundleId: 'org.Mata3.app',
        },
      },
    };

    try {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyASCbEH83P9EbeYHrL7ZHtyjqOpmYkOXQE`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        },
      );

      const data = await response.json();
      console.log('dynamic link,', data);
      // return the short link
      setUrl(data.shortLink);
    } catch (error) {
      console.error('Error creating dynamic link: ', error);
      throw error;
    }
  };

  return (
    <div>
      <Container>
        
        <Row className="d-flex justify-content-between">

          <div className='p-0'>

          <ShareContent url={url} hasInvite={true} />
          
            <div className="course-header">
              <div className='font-16 font-weight-600'>Course Description</div>
            </div>
            
            { course.description && (
            <div className="course-description">
              <div>{showAll ? course.description! : course.description!.slice(0, 300)}</div>
              {!showAll && course.description!.length > 300 && <p className='secondry-color cursor-pointer' onClick={handleShowMore}>See More</p>}
              {showAll && <p className='secondry-color cursor-pointer' onClick={handleShowLess}>See Less</p>}
            </div>
            ) }
            
            <div className='border rounded my-3 pb-3 px-3'>
              <div className="course-header mt-3">
                <div className='font-16 font-weight-600' >What’s Included :</div>
              </div>
              <div className="course-features row">


                <div className='col-md-6 mt-2'>
                <p>
                  
                  . {course.includes?.quiz} Quiz
                </p>
                </div>

                <div className='col-md-6 mt-2'>
                <p>
                  
                  . {course.includes?.assessment} Assesmsnts
                </p>
                </div>

                <div className='col-md-6 mt-2'>
                {course.includes?.certificate && (
                  <p>
                    
                    . Certificate
                  </p>
                )}
                </div>

                <div className='col-md-6 mt-2'>
                <p>
                  
                  . {course.includes?.files} Files
                </p>
                </div>
              </div>
            </div>
            

            <div className="course-content">
              <div className="course-header">
                <div className="font-16 font-weight-600 mt-4">Course Content</div>
                <p className="m-0 font-14 font-weight-400">
                  {course?.sections?.length} sections, {course.lessons_count}{' '}
                  lectures
                </p>
              </div>

              <div className="course-content-sections mt-3">
                {course?.sections?.map((section, index) => {
                  return (
                    <div className="section-container mb-4 border" key={index}>
                      <div
                        onClick={() => changeCollapeHandler(index)}
                        aria-controls="example-collapse-text"
                        aria-expanded={collapsed == index}
                        className="d-flex align-items-center section-item-collapse">
                        <div>{collapsed == index ? <FaChevronUp /> : <FaChevronDown />}</div>
                        <p className="section-title mx-2">{section.title}</p>
                        
                      </div>
                      <Collapse in={collapsed == index}>
                        <div id="example-collapse-text">
                          {section.lessons.map((lesson, indexs) => {
                            let type = lesson.content_type;

                            return (
                              <div
                                className="section-item"
                                key={indexs}>
                                <div className="icons-container d-flex justify-content-between">
                                  <div className='d-flex justify-content-between'>
                                    <div>
                                      {(lesson.content_type === 'video') && (
                                        // <Link to={`${type}`}>
                                        <img alt="img" src="/lesson-video.png" />
                                        // </Link>
                                      )}
                                      {lesson.content_type === 'Quizze' && (
                                        // <Link to={`${type}`}>
                                        <img alt="img" src="/lesson-quiz.png" />
                                        // </Link>
                                      )}
                                      {lesson.content_type === 'PracticalTask' && (
                                        // <Link to={`${type}`}>
                                        <img src="/lesson-practical.png" />
                                        // </Link>
                                      )}
                                      {lesson.content_type === 'pdf' && (
                                        <div>
                                          <img alt="img" src="/icons/pdf.png" width={25} />
                                        </div>
                                      )}
                                      {(lesson.content_type === 'meet') && (
                                        // <Link to={`${type}`}>
                                        <img alt="img" src="/lesson-video.png"/>
                                        // </Link>
                                      )}
                                    </div>

                                    <div>
                                      <p className="lecture-title">
                                        {lesson.content_type !== 'pdf' && (
                                          <div>{lesson.title}</div>
                                        )}
                                        {lesson.content_type === 'pdf' && (
                                          <div>{lesson.title}</div>
                                        )}
                                      </p>

                                      <p className="lecture-type">
                                        {/* <Link to={`${type}`}> */}
                                        {lesson.content_type === 'video' &&
                                          `Video , ${lesson.duration} Mins`}
                                        {/* </Link> */}
                                        {/* <Link to={`${type}`}> */}
                                        {lesson.content_type === 'Quizze' &&
                                          `Quiz , ${lesson.content_type} Questions`}
                                        {/* </Link> */}
                                        {/* <Link to={`${type}`}> */}
                                        {lesson.content_type === 'PracticalTask' &&
                                          `Upload Practical Task`}
                                        {/* </Link> */}
                                        
                                        
                                      </p>
                                    </div>
                                  </div>

                                  <div style={{ color: 'var(--blue)' , textDecoration: 'underline' , cursor: 'pointer' }}>
                                    {lesson.content_type === 'pdf' &&
                                      `Download`}
                                  </div>

                                </div>
                                {lesson.content_type == 'meet' && (       
                                <div className='text-gray-200 font-14 d-flex align-items-center justify-content-between'>
                                  <div className='lesson-type-feature mt-1 d-flex align-items-center'>
                                  
                                  <span>{lesson.content_type === 'meet' && `Meet`}</span>
                                  
                                  <div className='mx-2'>
                                    <img src="/cuida_calendar-outline.svg" alt="img" />
                                    <span className='mx-1'>{`${moment(lesson?.start_date_session).format('YYYY-MM-DD')}`}</span>
                                  </div>

                                  <img src="/bx_time-five.svg" alt="img" />
                                  
                                  <span className='mx-1'>{`${moment(lesson?.start_time, 'HH:mm:ss').format('hh:mm A')} - ${moment(lesson?.end_time, 'HH:mm:ss').format('hh:mm A')}`}</span>

                                  </div>
                                  <img
                                    alt="img"
                                    src="/tabler_lock.svg"
                                    width={25}
                                  />
                                </div>
                                )  }
                              </div>
                            );
                          })}
                        </div>
                      </Collapse>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="rate-content d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <RateStars rateValue={course.ratings} />

                <div className="rate-number mx-2">
                  ({course.ratings}) {course.ratings_count} Rate
                </div>
              </div>

              <SourceViews numbers={course.subscribers} />
            </div>

            <h5>{course.provider_data?.name}</h5>
          </div>

        </Row>
      </Container>
    </div>
  );
};

export default CourseSections;
