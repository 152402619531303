import {Col, Container, Row} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/SignUpEmail.module.css';
import {useState} from 'react';
import {sendOtp} from './Auth.service';
import {useNavigate} from 'react-router-dom';
import {useGoogleLogin} from '@react-oauth/google';
import {SocialLogin} from './Auth.service';
import toast, {Toaster} from 'react-hot-toast';
import BlueButton from '../../UI/blue-button/BlueButton';
import { NavLink } from 'react-router-dom';

const SignUpEmail = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [errorMail, setErrorMail] = useState<string>('');

  const [verifyAccountLink, setVerifyAccountLink] = useState<string>('/verify');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  const type = urlParams.get('type')
  const modelId = urlParams.get('model_id')
  const invitationId = urlParams.get('invitation_id')
  const invitee_id = urlParams.get('invitee_id')

  const check = async () => {
    let y = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setErrorMail('');
    if (email == '') {
      setErrorMail('Please enter a valid email');
    } else if (y.test(email) == false) {
      setErrorMail('Please enter a valid email');
    } else {
      let data = new FormData();
      data.append('email', email);

      let result = await sendOtp(data);
      console.log('result.response', result.response);

      if (result.response.status) {
        
        
        if(  type && modelId ){
          if( type == 'Initiative' ){ 
            navigate(`/verify?type=Initiative&model_id=${modelId}&invitation_id=${invitationId}${invitee_id ? `&invitee_id=${invitee_id}` : '' }`, {state: {email: email}});
          }
          else if( type == 'InitiativePackage' ){
            navigate(`/verify?type=InitiativePackage&model_id=${modelId}&invitation_id=${invitationId}${invitee_id ? `&invitee_id=${invitee_id}` : '' }`, {state: {email: email}});
          }else if( type == 'Event' ){
            navigate(`/verify?type=Event&model_id=${modelId}`, {state: {email: email}});
          }else if( type == 'tracking' ){
            navigate(`/verify?type=tracking&model_id=${modelId}`, {state: {email: email}});
          }else if( type == 'trackingPackage' ){
            navigate(`/verify?type=trackingPackage&model_id=${modelId}`, {state: {email: email}});
          }else if( type == 'Course' ){
            navigate(`/verify?type=Course&model_id=${modelId}`, {state: {email: email}});
          }else if( type == 'Consultant' ){
            navigate(`/verify?type=Consultant&model_id=${modelId}`, {state: {email: email}});
          }
          
        }
        else{
          if( type == 'InitiativesList' ){
            navigate('/user/initiatives/')
          }else if( type == 'FollowUpSystemsList' ){
            navigate('/user/trackings/list/')
          }else if( type == 'FollowUpSystemsList' ){
            navigate('/consultants/get-teachers')
          }else{
            navigate('/verify', {state: {email: email}});
          }
          
        }

        
      } else {
        setErrorMail(result.response.msg);
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log('tokenResponse', tokenResponse.access_token);

      // if (tokenResponse && tokenResponse?.accessToken) {
      console.log('tokenResponse2', tokenResponse.access_token);
      // Use the access token to fetch user details
      fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      })
        .then(response => response.json())
        .then(async user => {
          console.log(user);
          localStorage.setItem('email', user.email);
          localStorage.setItem('avatar', user.picture);
          let data = new FormData();
          data.append('provider', 'google');
          data.append('provider_id', user.id);
          data.append('email', user.email);
          // data.append('firebase_token', await gettoken());
          let result = await SocialLogin(data);
          console.log('resultresultresult', result.response);
          if (!result.response.success) {
            toast.error(result?.response?.message);
          } else if (result.response?.token) {
            console.log('social data ' , result.response);
            localStorage.setItem('logged', '1');
            localStorage.setItem('token', result.response.token);
            localStorage.setItem('name', result.response.user?.username);
            localStorage.setItem('isCons', result.response.user.is_consultant);
            localStorage.setItem('hasPassword', result.response.user.hasPassword);
            localStorage.setItem('is_social', '1');

            localStorage.setItem(
              'id',
              JSON.stringify(result.response.user?.id),
            );
            localStorage.setItem(
              'gender',
              result.response.user?.gender == 1 ? '1' : '2',
            );
            result.response.user?.avatar &&
              localStorage.setItem('avatar', result.response.user?.avatar);

              if( type && modelId ){
                
                
                if( type == 'Initiative' ){
                 
                  window.location.href = `/user/trackings/${modelId}/details?is_level=0&type=Initiative&model_id=${modelId}&invitation_id=${invitationId}`
                  
                }else if( type == 'InitiativePackage' ){
                 
                  window.location.href = `/user/initiatives/${modelId}/package/levels?discover=1&type=InitiativePackage&model_id=${modelId}&invitation_id=${invitationId}${invitee_id ? `&invitee_id=${invitee_id}` : '' }`
                }else if( type == 'Event' ){
                  window.location.href = `/event/${modelId}/details?type=Event&model_id=${modelId}`
                }else if( type == 'tracking' ){
                  window.location.href = `/user/trackings/${modelId}/details?is_level=0` 
                }else if( type == 'trackingPackage' ){
           
                  window.location.href = `/user/trackings/${modelId}/package/levels?discover=1`
                }else if( type == 'Course' ){
           
                  window.location.href = `/courses/${modelId}`
                }else if( type == 'Consultant' ){
           
                  window.location.href = `/consultants/consultant/${modelId}`
                }
              }else{
                window.location.href = '/';
              }
          } else {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const type = urlParams.get('type')
            const modelId = urlParams.get('model_id')
            const invitationId = urlParams.get('invitation_id')
          
            let inviteParems = ``
            if(  type && modelId ){
              if( type == 'Initiative' ){
                inviteParems += `?type=Initiative&model_id=${modelId}&invitation_id=${invitationId}${invitee_id ? `&invitee_id=${invitee_id}` : '' }`
              }else if( type == 'InitiativePackage' ){
                inviteParems = `?type=InitiativePackage&model_id=${modelId}&invitation_id=${invitationId}${invitee_id ? `&invitee_id=${invitee_id}` : '' }`
              }else if( type == 'Event' ){
                inviteParems = `?type=Event&model_id=${modelId}`
              }else if( type == 'tracking' ){
                inviteParems = `?type=tracking&model_id=${modelId}`
              }else if( type == 'trackingPackage' ){
                inviteParems = `/?type=trackingPackage&model_id=${modelId}`
              }else if( type == 'Course' ){
                inviteParems = `/?type=Course&model_id=${modelId}`
              }else if( type == 'Consultant' ){
                inviteParems = `/?type=Consultant&model_id=${modelId}`
              }
            }

            localStorage.setItem('hasPassword', 'false');
            localStorage.setItem('is_social', '1');

            navigate(`/register${inviteParems}`, {
              state: {
                social: true,
                provider_id: user.id,
                provider: 'google',
                email: user.email,
              },
            });
          }
        })
        .catch(error => {
          console.error('Error fetching user details:', error);
        });
    },
  });

  return (
    <div>
      <Container className={classes.FormContainer}>
        <Toaster position="top-right" />
        <Row className="d-flex justify-content-between">
          <Col md={6} >
            <div className={classes.FormPadding}>
              {/* <h3 className={`fontWeight-bold ${classes.HeaderTitle} `}>Sign Up</h3>
                          <p className={`${classes.HeaderPargraph}`}>Enter Your Email Address To Confirm</p> */}

              <div className='text-center'>
                {/*
                <img src="/champ-logo.png" alt="img" width={120} />
                */}
                <h2 className={classes.SignTitle}>Sign Up</h2>
                <p className={`${classes.GrayTitle}`}>
                Please enter your email address
                </p>
              </div>        

              <div className="mt-3 mb-3 position-relative">
                {/*
                <label className="mb-2">Please enter your email</label>
                */}
                <div className="position-relative">
                  <img src="/icons/carbon_email.svg" alt="img" width={25} className='img-input-placeholder' />
                  <input
                    type="email"
                    className={`form-control m-0 ${classes.EmailInput}`}
                    required
                    placeholder="Please enter your email"
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className={classes.error}>{errorMail}</div>
              </div>

              <div className="mt-4 mb-2">
                <BlueButton text="Sign Up" onClick={check} />
              </div>

              <p className={`fontWeight-bold ${classes.HeaderTitle}`}>
                <span>Or</span>
              </p>  

              <div onClick={() => login()} className={classes.GoogleSignIn}>
                <img src="/icons/devicon_google.svg" alt="img" /> Sign Up with Google
              </div> 

              <Col md="12">
              <div className="col-12 text-center my-3">
                    
                    <small>
                      You Don't have an account? &nbsp;
                    </small>

                    <NavLink
                      to={'/login'}
                      className={`login-link`}>
                      {' '}
                      Log in
                    </NavLink>
                  
                </div>
              </Col>

            </div>
          </Col>

          <Col xs={12} lg={6}>
            <img
              src="/kogin-bg.png"
              alt="img"
              className={classes.SignUpBackground}
            />
          </Col>

          
        </Row>
      </Container>
    </div>
  );
};

export default SignUpEmail;
