import CircleProgress from '../../../UI/circle-progress/CircleProgress';
import {TrackingItemProps} from '../interfaces/ITrackings';
import classes from '../style/TrackingCard.module.css';
import {useNavigate} from 'react-router-dom';
import {getUserId} from '../../../auth/utiles';
const TrackingCard = ({
  tracking,
  showProgress,
  isPackage,
  enrolled,
  levelList,
}: TrackingItemProps) => {
  const navigate = useNavigate();

  const userId = getUserId() ?? '';

  return (
    <div
      className={`${classes.Item} d-flex row`}
      onClick={() => {
        if (isPackage) {
          navigate(`/user/tracking-system/levels`, {
            state: {
              item: tracking,
              tab: enrolled,
            },
          });
        } else {
          if (levelList) {
            if (tracking.is_enrolled) {
              navigate(`/user/tracking-system/single/list`, {
                state: {
                  item: tracking,
                  tab: enrolled,
                },
              });
            } else {
              navigate(`/user/tracking-system/intro/${tracking.id}`);
            }
          } else if (tracking.is_enrolled) {
            navigate(`/tracking-system/${tracking.id}/details`, {
              state: {
                start_date: tracking.start_date,
                end_date: tracking.end_date,
                item: tracking,
              },
            });
          } else {
            navigate(`/user/tracking-system/intro/${tracking.id}`);
          }
        }
      }}>
      <h5 className="font-weight-bold p-0 mb-3">{tracking.name}</h5>
      {tracking.is_enrolled ? (
        <div className={`${classes.Owner}`}>
          {isPackage
            ? 'levels'
            : tracking?.username == tracking?.main_enrolled_user_name &&
              tracking?.enrolled_members.length == 0
            ? 'Me'
            : tracking?.enrolled_members.length > 0 &&
              tracking?.has_unlimited_enrollment
            ? 'Multi Users'
            : tracking?.username}
        </div>
      ) : isPackage ? (
        <div className={`${classes.Owner}`}>levels</div>
      ) : null}

      <div className={`${classes.Image} col-4`}>
        <img
          src={tracking.image ? tracking.image : '/logo.png'}
          alt="course-img"
          style={{
            width: tracking.image ? '180px' : '100px',
            height: tracking.image ? '160px' : 'auto',
          }}
        />
      </div>
      <div
        className={`${classes.Details} col-8 d-flex flex-column justify-content-between`}>
        <div style={{margin: '0 5px'}}>
          <div className={`d-flex justify-content-between`}>
            {tracking.is_enrolled ? (
              <div className={`${classes.Price}`}>Enrolled</div>
            ) : (
              <div className={`${classes.Price}`}>
                {tracking.price == 0 ? 'Free' : `${tracking.price} ${tracking?.user_currency}`}
              </div>
            )}
            <div>
              {tracking.belongs_to_level_tree ? (
                <span className={`${classes.Level}`}>
                  Level {tracking.level}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>

          <h4>
            {tracking.provider_name ? `${tracking.provider_name}` : `Mataa3`}{' '}
          </h4>

          <p>
            {tracking.description.length > 20
              ? tracking.description.substring(0, 20) + ' ...'
              : tracking.description}
          </p>

          {((tracking.has_unlimited_enrollment == 0 &&
            tracking.is_enrolled == 1 &&
            tracking.has_started_tracking == 1) ||
            (tracking.has_unlimited_enrollment == 1 &&
              tracking.is_enrolled == 1 &&
              tracking.has_started_tracking == 1) ||
            (tracking.is_enrolled == 1 &&
              tracking.finished_current_tracking == 0 &&
              tracking.has_started_tracking == 1 &&
              tracking.has_unlimited_enrollment == 0)) && (
            <div className="d-flex">
              <CircleProgress
                value={
                  tracking?.total_progress
                    ? tracking.total_progress.toFixed(1)
                    : 0
                }
              />
            </div>
          )}

          <div className="d-flex text-center">
            {tracking.is_enrolled &&
            tracking.enrolled_members.length > 0 &&
            tracking.has_unlimited_enrollment
              ? tracking.enrolled_members.map((item, index) => (
                  <div key={index} style={{margin: '0 10px'}}>
                    <img
                      src={item.avatar ?? '/icons/manHolder.png'}
                      style={{
                        width: '20px',
                        height: '25px',
                        margin: 'auto',
                        borderRadius: '50%',
                      }}
                    />
                    <h6>
                      {item.username.length > 7
                        ? item.username.substring(0, 7) + ' ...'
                        : item.username}
                    </h6>
                  </div>
                ))
              : ''}
          </div>
        </div>

        {((tracking?.has_unlimited_enrollment == 0 &&
          tracking?.is_enrolled == 1 &&
          tracking?.has_started_tracking == 0) ||
          (tracking?.has_unlimited_enrollment == 1 &&
            tracking?.is_enrolled == 1 &&
            tracking?.has_started_tracking == 0)) && (
          <h5 className="secondry-color">Not Started Yet</h5>
        )}

        <div className={`${classes.Date}`}>
          {tracking?.is_unlimited == 1 && tracking?.is_enrolled == 0 && (
            <span style={{fontWeight: '600'}} className="text-dark mr-1 ml-1">
              <img
                src={'/icons/coin.png'}
                alt="course-img"
                style={{
                  marginRight: '10px',
                  width: '20px',
                  verticalAlign: 'sub',
                }}
              />

              <span>Limited</span>
              <span> {tracking?.price} {tracking?.user_currency}</span>
              <span> - </span>
              <span>Unlimited</span>
              <span> {tracking?.unlimited_price} {tracking?.user_currency}</span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackingCard;
