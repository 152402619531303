export const getDomain = () => {
  
    let domainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' || hostName == 'webapp-dev.mataa3.com' ){
        domainName = 'https://apidev.mataa3.com'
    }

    else if( hostName == 'webapp-test.mataa3.com' ){
        domainName = 'https://apitest.mataa3.com'
    }

    else if(hostName == 'webapp-main.mataa3.com' || hostName == 'mataa3.com' ){
        domainName = 'https://api.mataa3.com'
    }
    
    else{
        domainName = 'https://apidev.mataa3.com'
    }
    
    
    return domainName
  
};
