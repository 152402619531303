import {
  ButtonToolbar,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import {FaCircle, FaLongArrowAltRight, FaRegCalendarAlt} from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/UserTrackingSystem.module.css';
import {NavLink, useLocation, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import { getDynamicLinkDomain } from '../../helpers/getDynamicLinkDomain';

import {
  addCart,
  assignAndStartTrack,
} from '../Tracking/Trackings.service';

import { getmemberForAssign , getEventDetails , enrollFree} from './Events.service';


import {isUserLoggedIn, getUserId} from '../../auth/utiles';
import {useNavigate} from 'react-router-dom';
import CancelButton from '../../UI/cancel-button/CancelButton';
import CustomCheckbox from '../Tracking/components/CustomCheckbox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ShareIcon from '../../components/share-icon/ShareIcon';

const EventDetails = () => {

  const location = useLocation();

  const [url, setUrl] = useState('https://mataa3.page.link');

  const queryParams = new URLSearchParams(location.search);
  const [isLevel, setIsLevel] = useState(queryParams.get('is_level') || '0');

  const [userEnrolled, setUserEnrolled] = useState(queryParams.get('userEnrolled') || '0');

  const navigate = useNavigate();

  const isLoggedIn = isUserLoggedIn();

  const userId = getUserId() ?? '';

  const cartData = useAppSelector(state => state.cart.data);

  const {id} = useParams();

  const dispatch = useAppDispatch();

  const [item, setItem] = useState(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const [enrolled, setEnrolled] = useState(false);

  const [show, setShow] = useState(false);

  const [showAssignUsers, setShowAssignUsers] = useState(false);

  const [showPriceLimitation, setShowPriceLimitation] = useState(false);

  

  const [members, setMembers] = useState([]);

  const [member, setMember] = useState(null);

  const [selectedValues, setSelectedValues] = useState([]);

  const [unlimited, setUnLimited] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    document.getElementById('video').play();
  };

  const existInCart = () => {
    return cartData.find(i => i.name == item.title);
  };

  const enroll = async () => {
    dispatch(setLoading(true));
    try {
      let data = new FormData();

      destinationArray.map((i, index) =>
        data.append(`user_ids[${index}]`, i.id),
      );
     
      data.append('event_id', String(id));

      

      let result = await enrollFree(data);
      if (result.response) {
        setEnrolled(true);
        dispatch(setLoading(false));
        setShow(false);

        navigate('/events-list/')
      }
    } catch (e) {
      console.log({e});
    }
  };

  const addToCart = async () => {
    if (isLoggedIn) {
      dispatch(setLoading(true));
      try {
        let data = new FormData();
        for (let i = 0; i < destinationArray.length; i++) {
          data.append('model_id', String(id));
          data.append('model_name', 'Event');

          data.append(`items[${i}][model_id]`, String(id));
          data.append(`items[${i}][model_name]`, 'Event');
          data.append(`items[${i}][user_id]`, destinationArray[i].id);
        }
        
        
        let result = await addCart(data);
        console.log('event added to cart', result);
        dispatch(setLoading(false));
        setShowAssignUsers(false);
      } catch (e) {
        console.log({e});
      }
    } else {
      navigate(`/login`);
    }
  };

  useEffect(() => {
    getData();
    getMembers();
  }, []);

  const getData = async () => {
    dispatch(setLoading(true));
    let result = await getEventDetails(id);
    let record = result.response
      if( record && record.not_available){
        navigate('/not-available')
    }else{
      console.log('event details ', result.response.data )
      setItem(record.data);
    }
    dispatch(setLoading(false));
  };

  const getMembers = async () => {
    let result = await getmemberForAssign(id);

    if (result.response?.data.length > 0) {
      console.log('assign users', result.response.data);

      let data = result.response.data;

      //const newData = data.map(item => ({
      //...item,
      //id: item.id.toString(), // Convert id to string
      //}));

      let newData = data.map(item => ({...item, checked: false}));

      setSourceArray(newData);
    }
  };

  useEffect(() => {
    if( item ){
      createDynamicLink()
    }
  }, [item]);

  const createDynamicLink = async () => {
    const requestBody = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://mataa3.page.link',
        link: `${getDynamicLinkDomain()}/?type=Event&model_id=${id}`,
        androidInfo: {
          androidPackageName: 'com.together.mata3',
        },
        iosInfo: {
          iosBundleId: 'org.Mata3.app',
        },
      },
    };

    try {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyASCbEH83P9EbeYHrL7ZHtyjqOpmYkOXQE`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        },
      );

      const data = await response.json();
      console.log('dynamic link,', data);
      // return the short link
      setUrl(data.shortLink);
    } catch (error) {
      console.error('Error creating dynamic link: ', error);
      throw error;
    }
  };

  const handleCheckboxChange = event => {
    const {value, checked} = event.target;
    console.log('value, checked', value, checked);
    if (checked) {
      // If checkbox is checked, add the value to the selectedValues array
      setSelectedValues(prevSelectedValues => [...prevSelectedValues, value]);
    } else {
      // If checkbox is unchecked, remove the value from the selectedValues array
      setSelectedValues(prevSelectedValues =>
        prevSelectedValues.filter(item => item !== value),
      );
    }

    console.log('selectedValues', selectedValues);
    //const selectedObjects = checkboxes.filter(obj => selectedValues.includes(obj.value));
    //console.log('selectedObjects' , selectedObjects )
  };

 

  const popoverBottom = (
    <Popover
      id="popover-positioned-bottom"
      title="Popover bottom"
      style={{
        background: '#eee',
        fontSize: '1rem',
        border: 'none',
        padding: '1rem',
      }}>
      Limited - Only you or a family member can start this regimen and you can
      not re-take it after you have finished it.
    </Popover>
  );

  const [sourceArray, setSourceArray] = useState([]);

  const [destinationArray, setDestinationArray] = useState([]);

  const handleCheckboxChangeItems = id => {
    // Find the item in the source array
    const selectedItem = sourceArray.find(item => item.id === id);

    if (selectedItem) {
      selectedItem.checked = true;
      // Update checked status in the source array
      const updatedSourceArray = sourceArray.map(item =>
        item.id === id ? {...item, checked: !item.checked} : item,
      );

      // Update the state with the modified source array
      const newSourceData = sourceArray.filter(item => item.id !== id);
      setSourceArray(newSourceData);

      setDestinationArray(prevArray => [...prevArray, selectedItem]);
    }
  };

  const handleDestinationCheckboxChangeItems = id => {
    // Find the item in the source array
    const selectedItem = destinationArray.find(item => item.id === id);

    if (selectedItem) {
      selectedItem.checked = false;

      setSourceArray(prevArray => [...prevArray, selectedItem]);

      // Update the state with the modified source array
      const newDestinationData = destinationArray.filter(
        item => item.id !== id,
      );

      setDestinationArray(newDestinationData);
    }
  };

  

  return (
    <>
      <Container className="mt-5 mb-5">
        <Row>
          <Col md={6} className={classes.TrackingDetails}>
            <div>
              <h1 className="main-title mb-4">{item?.title}</h1>

              { userEnrolled == '0'  && item?.paid != 2 ?
              <div className="mb-2">
                 
                <div className="d-flex justify-content-between">
                  
                  { item?.paid == 1 ? 
                  <div style={{fontSize: '1.2rem'}}>
                   
                    
                      <span
                        style={{fontWeight: '600'}}
                        className="text-dark mr-1 ml-1">
                        
                        <span> {item?.price} {item?.user_currency}</span>
                      </span>
                    
                  </div>
                  : 'Free' }

                 

                </div>
              </div>
              : '' }

            
              
              <div>
                <div className='d-flex justify-content-between align-items-center'>
                <h4>About Event</h4>
                <ShareIcon url={url} hasInvite={true} />
                </div>
                
                {/*<div dangerouslySetInnerHTML={{ __html: `${item?.long_description}`}} />*/}
                <ReactQuill theme="bubble" value={item?.long_description} readOnly={true}  />
              </div>

             
              {/*
              <div class="d-flex align-items-center mt-3 mb-3"><img src="/calendar_line.svg" alt="img" /><p class="card-custom-date">{item?.published_date_time}</p></div>
              */}

             
              { userEnrolled == '0' && item?.paid != 2 ?
              <div>
                {item?.paid ? (
                  <MainButton
                    text={existInCart() ? 'View Cart' : 'Add To Cart'}
                    onClick={() => {
                      if (existInCart()) {
                        navigate('/cart');
                      } else {
                        setShowAssignUsers(true);
                        
                      }
                    }}
                  />
                ) : (
                  ''
                )}
                {item?.paid == 0 ? (
                  <div onClick={() => setShowAssignUsers(true)}>
                    <MainButton text="Enroll Now" />
                  </div>
                ) : (
                  ''
                )}
              </div>
              : '' }

                <div className="d-none text-center">
                {
                item?.enrolled_members.length > 0 && userEnrolled == '1'
              
                  ? item.enrolled_members.map((item, index) => (
                      <div key={index} style={{margin: '0 10px'}}>
                        <img
                          src={item.avatar ?? '/icons/manHolder.png'}
                          style={{
                            width: '20px',
                            height: '25px',
                            margin: 'auto',
                            borderRadius: '50%',
                          }}
                        />
                        <h6>
                          {item.username.length > 7
                            ? item.username.substring(0, 7) + ' ...'
                            : item.username}
                        </h6>
                      </div>
                    ))
                  : ''}
                </div>

            </div>
          </Col>

          <Col md={6} className={classes.TrackingMedia}>
            <Card className={`event-card-details ${classes.TrackVideo}`}>
              <div className={classes.TrackImgContainer}>
                <div className={classes.CardImgContainer}>
                  <img
                      className="card-img-top"
                      alt="courseImg"
                      src={item?.image}
                    />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>

      {/*assign user pop up*/}
      <Modal
        show={showAssignUsers}
        onHide={() => setShowAssignUsers(false)}
        centered>
        <Modal.Body>
          {sourceArray.length ? (
            <h5 className="secondry-color mt-4 mb-3">
              Choose one or more family member
            </h5>
          ) : (
            <h5 className="secondry-color mt-4 mb-3">
              No member family found as all member are assigned to this
              event
            </h5>
          )}

          {/*new array*/}
          {sourceArray.map((item, i) => (
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              key={item.id}>
              <div>
                <img
                  src={item.avatar ?? '/icons/manHolder.png'}
                  alt="image"
                  style={{
                    width: '60px',
                    borderRadius: '50%',
                    height: '60px',
                    margin: '0 20px 0 0',
                  }}
                />
                <span>{item.username}</span>
              </div>

              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => handleCheckboxChangeItems(item.id)}
                  />
                </div>
              </div>
            </div>
          ))}

          {destinationArray.length ? (
            <h5 className="secondry-color mt-4 mb-3">
              Selected family members
            </h5>
          ) : (
            ''
          )}

          {destinationArray.map(item => (
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              key={item.id}>
              <div>
                <img
                  src={item.avatar ?? '/icons/manHolder.png'}
                  alt="image"
                  style={{
                    width: '60px',
                    borderRadius: '50%',
                    height: '60px',
                    margin: '0 20px 0 0',
                  }}
                />
                <span>{item.username}</span>
              </div>

              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() =>
                      handleDestinationCheckboxChangeItems(item.id)
                    }
                  />
                </div>
              </div>
            </div>
          ))}
          {/*new array*/}
          <hr />
          {item?.price && destinationArray.length ? (
            <div
              className="d-flex justify-content-between"
              style={{fontWeight: 'bold'}}>
              <p>Total</p>
              { unlimited ?
              <p>{item?.unlimited_price * destinationArray.length} {item?.user_currency}</p>
              :
              <p>{item?.price * destinationArray.length} {item?.user_currency}</p>
              }
              
            </div>
          ) : (
            ''
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={item?.paid ? 'Add To Cart' : 'Enroll Now'}
              onClick={() => {
                if (item?.paid) {
                  addToCart();
                } else {
                  enroll();
                }
              }}
              disabled={destinationArray.length == 0}
            />
          </div>

          <div onClick={() => setShowAssignUsers(false)} className="w-100">
            <CancelButton text="Cancel" />
          </div>
        </Modal.Footer>
      </Modal>

      
    </>
  );
};

export default EventDetails;
