import moment from 'moment';
import {removeCart} from '../Cart.services';
import {CartDataProp} from '../interface/ICart';
import '../style/ShoppingCartItem.css';
import { getUserCurrency } from '../../../helpers/getUserCurrency';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import MainButton from '../../../UI/main-button/MainButton';
import CancelButton from '../../../UI/cancel-button/CancelButton';
import { useState } from 'react';

const ShoppingCartItem = ({data}: CartDataProp) => {
  const {t} = useTranslation();
  const userCountry = getUserCurrency()
  
  const getType = (type:any, startDate:any, date:any , tracking_type:any ) => {
    if( type == 'Tracking' ){
      if( startDate ){
        return 'Competition'
      }else{
        return 'Tracking'
      }
    }else if ( type == 'TrackingPackage' ){
      if( tracking_type == 'tracking' ){
        return 'Follow-up System Package'
      }else{
        return 'All Levels'
      }
    }if( type == 'Course' ){
      if( date ){
        return 'Live Course'
      }else{
        return type
      }
    }
    else{
      return type
    }
  };

  const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState(false);
  const [showConfirmDeleteItem, setShowConfirmDeleteIem] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState<any>(null);

  return (
    <>
    <div className="mb-4 border rounded d-flex row">
      
      {/* <div className="course-image col-md-3">
        <img src={data.image} alt="course-img" />
      </div> */}
      <div className="p-1 p-md-4 col-md-12">
        <div className="d-flex flex-column justify-content-between h-100">
          

          

          

          
          <div className="d-flex justify-content-between align-items-center">
            {/*
            <div>
              <div className="discount-price" style={{fontSize: '2rem'}}>
                {data.price} {userCountry}
              </div>
            </div>
            */}

            <h5
            style={{
              color: '#2F5466'
            }}
            className='font-18 font-weight-700'
            >
              {data?.name}
            </h5>

            

            <div
            onClick={() => {
              if( (data.model_name == 'Tracking' || data.model_name == 'TrackingPackage') && data.tracking_type == 'initiative'  ){
                setShowConfirmDeleteIem(true)
              }else{
                removeCart(data.id,data.is_soldout,data.is_not_available,data.model_id,data.model_name);
              }
            }}
            >
              <img src={'/red-delete.svg'} alt="course-img"/>
            </div>
            

            
          </div>

          <span className='challenge-cart-label'>
              {getType(data?.model_name , data?.start_date , data?.date , data?.tracking_type )}
              </span>

          <div>

          </div>

          


          <div className='d-flex align-items-center mt-2'>   
          { data.users_names && data.users_names.length > 0 ? <img src={'/user-gray.svg'} alt="course-img" /> : '' }    
          {data.users_names && data.users_names.map((item:any, i:any) => {

          return (

            <div className='user-cart-data mx-2 user-placeholder'
            onClick={() => {
              setSelectedMemberId(item.cart_id)
              setShowConfirmDeleteUser(true)
            }}
            >
              <img src={'/x.svg'} alt="course-img"/>
              <span className='mx-1'>{item.username}</span>
            </div>

          );
          })}

             
          </div>   
          

          
          <div className='d-flex justify-content-between'>
              <div className='user-cart-data red-color font-18 font-weight-600'>
                
                <span className='font-weight-bold red-color'>{data.price} {userCountry}</span>
              </div>

              
          </div>

          {data.model_name == 'ConsultationSession' ? 
            <div className="mb-2 d-flex align-items-center mt-2" style={{ fontSize : '15px' , fontWeight : '400' }}>
              <img src="/lets-icons_date-range-fill.svg" alt="img" /> <span className='mx-1'>{moment(data.date).format('dddd DD-MM-YYYY hh:mm A')}</span>
            </div>
          : '' }

          {data.tracking_type == 'initiative' ? 
            <div className="mb-2" style={{ fontSize : '15px' , fontWeight : '400' }}>
              <img src="/lets-icons_date-range-fill.svg" alt="img" /> {moment(data.date).format('DD-MM-YYYY')}
            </div>
          : '' }

        </div>
      </div>

      

      {data.is_soldout
      ?        
      <div
      style={{
        background: '#c7c7c7',
        textAlign: 'center',
        fontSize: '22px',
        padding: '1rem',
        borderRadius: '0px 0 10px 10px'

      }}  
      >
        <strong>{data.is_soldout ? 'Sold Out' : '' }</strong>
      </div>
      :
      ''
      }

      {data.is_not_available
      ?        
      <div
      style={{
        background: '#c7c7c7',
        textAlign: 'center',
        fontSize: '22px',
        padding: '1rem',
        borderRadius: '0px 0 10px 10px'

      }}  
      >
        <strong>{data.is_not_available ? 'Un Covered Country' : '' }</strong>
      </div>
      :
      ''
      }
    </div>

    {/*Show delete assigned user*/}
    <Modal show={showConfirmDeleteUser} onHide={() => setShowConfirmDeleteUser(false)} centered size='sm'>
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <Modal.Body>
      <h5 className="text-center">
          <img src={'/confirm.svg'} />
          <h2 className='popup-header'>
            {t('Delete a family member from the subscription list')}
          </h2>
          <p className='popup-desc'>
          {t('You are about to delete a family member from your subscriptions list. Are you sure about that?')}
          </p>
      </h5>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-100" onClick={() => {
          removeCart(selectedMemberId,data.is_soldout,data.is_not_available,null,null);
        } }>
          <MainButton text={t('Confirm')} />
        </div>
        <div onClick={() => setShowConfirmDeleteUser(false)} className="w-100">
          <CancelButton text={t('Cancel')} />
        </div>
      </Modal.Footer>
    </Modal>

    {/*Show delete assigned user*/}
    <Modal show={showConfirmDeleteItem} onHide={() => setShowConfirmDeleteIem(false)} centered size='sm'>
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <Modal.Body>
      <h5 className="text-center">
          <img src={'/big-delete.svg'} />
          <h2 className='popup-header'>
            {t('Delete subscription list competition')}
          </h2>
          <p className='popup-desc'>
          {t('You are about to delete a competition from your subscription list. Are you sure about that?')}
          </p>
      </h5>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-100" onClick={() => {
          removeCart(data.id,data.is_soldout,data.is_not_available,data.model_id,data.model_name);
        } }>
          <MainButton text={t('Confirm')} />
        </div>
        <div onClick={() => setShowConfirmDeleteIem(false)} className="w-100">
          <CancelButton text={t('Cancel')} />
        </div>
      </Modal.Footer>
    </Modal>
    </>


  );
};

export default ShoppingCartItem;
