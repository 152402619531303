import {NavLink, useNavigate} from 'react-router-dom';
import classes from '../style/ConsultantSessionsBtnActions.module.css';
import {colors} from '../../../constants/colors';
import toast, {Toaster} from 'react-hot-toast';
import {ConsultantSessionsBtnActionsProp} from '../interfaces/IConsultants';

function ConsultantSessionsBtnActions({
  consId,
  showFree,
  enableFree,
  availableFreeSessions,
  firstFree,
  navToFree,
  item,
}: ConsultantSessionsBtnActionsProp) {
  const navigate = useNavigate();
  let myId = localStorage.getItem('id');
  return (
    <>
      <Toaster position="top-right" />

      {showFree && (
        <div
          className={`${classes.ConsultantBtnAction}`}
          onClick={() => {
            // navToFree();
            if (availableFreeSessions > 0 && firstFree == 2) {
              toast.error('You have took your free session');
            } else if (availableFreeSessions == 0) {
              toast.error('You have no more free sessions this month');
            } else {
              navToFree();
            }
          }}
          style={{
            background: enableFree ? '#fff' : colors.regularGray,
            color: '#0AD49A',
            fontWeight: '500',
            fontSize: '14px',
            border: '1px solid #0AD49A',
            borderRadius: '8px',
          }}>
          
          <img src="/free-session.svg" alt="chat" />
          <span className='mx-2'>Book Free Session</span>
        </div>
      )}
      <NavLink
        className={`${classes.ConsultantBtnAction}`}
        to={`/consultants/request/private/session/${consId}`}
        style={{
          background: '#fff',
          color: '#ED61A4',
          fontWeight: '500',
          fontSize: '14px',
          border: '1px solid #ED61A4',
          borderRadius: '8px',
        }}
        >
        <img src="/request-session.svg" alt="chat" />
        <span className='mx-2'>Request Private Session</span>
      </NavLink>
      <div
        className={`${classes.ConsultantBtnAction}`}
        onClick={() => {
          if (item?.enrolled_with_consultant == 1) {
            navigate(`/chat/${myId}/${consId}`, {
              state: {
                current: myId,
                guest: consId,
                senderName: localStorage.getItem('name'),
                recName: item?.username,
                senderAvatar: localStorage.getItem('avatar'),
                recAvatar: item?.avatar,
                senderFCM: 'await getToken()',
                recFCM: item?.consultant_firebase_tokens,
              },
            });
          } else {
            toast.error(
              'You can start a conversation with the expert after booking the session',
            );
          }
        }}
        style={{
          background: '#fff',
          color: '#FF9640',
          fontWeight: '500',
          fontSize: '14px',
          border: '1px solid #FF9640',
          borderRadius: '8px',
        }}
        >
        <img src="/request-chat.svg" alt="chat" />
        <span className='mx-2'>Contact Mentor</span>
      </div>

      
      
    </>
  );
}

export default ConsultantSessionsBtnActions;
