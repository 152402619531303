import './PurpleButton.css';
import Button from 'react-bootstrap/Button';
function PurpleButton({text, ...res}) {
  return (
    <Button className="card-purple-button" variant="primary" {...res}>
      {text}
    </Button>
  );
}

export default PurpleButton;
