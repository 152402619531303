import {Col, Container, Row} from 'react-bootstrap';
import './style/ConsultantProfile.css';
import ConsultantCard from './components/ConsultantCard';
import ConsultantTabs from './components/ConsultantTabs';
import {useNavigate, useParams} from 'react-router-dom';
import {getConsultantData, getSurvey} from './Consultants.service';
import {useEffect, useState} from 'react';
import {ConsultantParams} from './types';
import {ConsultantsType} from './interfaces/IConsultants';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import {Questionnaire} from './interfaces/IQuestionnaire';
import ConsultantSessionsBtnActions from './components/ConsultantSessionsBtnActions';
import { getDynamicLinkDomain } from '../../helpers/getDynamicLinkDomain';
import ShareIcon from '../../components/share-icon/ShareIcon';
import ShareContent from '../../components/share-content/ShareContent';

const Consultant = () => {
  const navigate = useNavigate();
  let {id}: ConsultantParams = useParams<ConsultantParams>();
  const [item, setItem] = useState<ConsultantsType | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [survey, setSurvey] = useState<Questionnaire | null | undefined>();
  const [tab, setTab] = useState<string>('');

  const steps = [
    {
      number: "01",
      title: "Login / Register",
      description: "Create an account or log in to the Mataa3 platform.",
    },
    {
      number: "02",
      title: "Select Mentor",
      description: "Choose between the best mentors in our platform.",
    },
    {
      number: "03",
      title: "Book Session",
      description: "Choose the date that suits you from the calendar.",
    },
    {
      number: "04",
      title: "Checkout",
      description: "Add session to cart and continue to checkout.",
    },
  ];

  useEffect(() => {
    getData();
    getSurv();
  }, []);

  const getData = async () => {
    setLoading(true);
    let result = await getConsultantData(id);

    if (result.response.data) {
      setItem(result.response.data);
      // getCourses(coursesPage);
      // getTracking(trackingPage);
    }
    setLoading(false);
  };

  const getSurv = async () => {
    let result = await getSurvey(id);
    if (result.response) {
      setSurvey(result.response.data);
    }
  };

  useEffect(() => {
    createDynamicLink();
  }, []);

  const [url, setUrl] = useState('https://mataa3.page.link');

  const createDynamicLink = async () => {
   
    const requestBody = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://mataa3.page.link',
        link: `${getDynamicLinkDomain()}/?type=Consultant&model_id=${id}`,
        androidInfo: {
          androidPackageName: 'com.together.mata3',
        },
        iosInfo: {
          iosBundleId: 'org.Mata3.app',
        },
      },
    };

    try {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyASCbEH83P9EbeYHrL7ZHtyjqOpmYkOXQE`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        },
      );

      const data = await response.json();
      console.log('dynamic link,', data);
      // return the short link
      setUrl(data.shortLink);
    } catch (error) {
      console.error('Error creating dynamic link: ', error);
      throw error;
    }
  };
  return (
    <div>

      <div style={{
        background: 'var(--babyBlue)',
        backgroundImage: "url('/side-icon-1.svg'), url('/side-icon-2.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left bottom, right bottom',

      }}>
      <Container>
        <div className="container text-center py-5 mb-4">
          <p className="section-header">Start Online Counseling in 4 Easy Steps:</p>
          <p className="section-desc">Easy Steps to Find the Right Online Mentor.</p>
          <div className="divider"></div>

          <div className="row justify-content-center mt-5">
            {steps.map((step, index) => (
              <div key={index} className="col-md-3 text-center step-container">
                <div className={`circle ${index % 2 === 0 ? "pink" : "blue"}`}>
                  {step.number}
                </div>
                <h5 className="mt-3">{step.title}</h5>
                <p>{step.description}</p>
                {index !== steps.length - 1 && <img src={'/right-arrow.svg'} className="arrow" />}
              </div>
            ))}
          </div>
        </div>
      </Container>
      </div>

      <Container>
        
        <Row className="d-flex justify-content-center">
          <Col md={3} className='consultnant-profile-card'>

            <ConsultantCard
              consultant={item}
              // specs={
              //   <Fragment>
              //     <div
              //       className="d-flex align-items-center mt-4 d-none"
              //       style={{
              //         justifyContent: 'space-evenly',
              //         borderBottom: '1px solid #eee',
              //         paddingBottom: '20px',
              //       }}>
              //       <div>
              //         <FaClock color="#fb5465" style={{fontSize: '1.5rem'}} />{' '}
              //         30 Min
              //       </div>

              //       <div>
              //         <FaDollarSign
              //           color="#65c890"
              //           style={{fontSize: '1.5rem'}}
              //         />{' '}
              //         20 $
              //       </div>
              //     </div>
              //     <div className="mt-3">
              //       {/*<p
              //                           style={{
              //                               color: '#fb5465',
              //                               fontWeight: '600'
              //                           }}
              //                       >
              //                           Ahmed Completed 30 Successful Session
              //                       </p>*/}
              //       <MainButton text="Request Consultation" />
              //     </div>
              //   </Fragment>
              // }
            />
            {tab == 'sessions' && (
              <div className="consultant-actions-btns">
                <div>
                  <ConsultantSessionsBtnActions
                    consId={id!}
                    showFree={
                      item?.first_session_free !== 0 &&
                      item?.consultation_info_disable == 0
                    }
                    enableFree={
                      item?.available_free_sessions! > 0 &&
                      item?.first_session_free !== 2
                    }
                    item={item}
                    firstFree={item?.first_session_free!}
                    availableFreeSessions={item?.available_free_sessions!}
                    navToFree={() => {
                      navigate('/consultants/sessions/free', {
                        state: {consultant: item},
                      });
                    }}
                  />
                </div>
              </div>
              )}
            
          </Col>

          <Col  md={7}>
            <div className='mb-3'>
              <ShareContent url={url} hasInvite={true} />
            </div>
            <div className="mb-3 d-flex justify-content-between align-items-center">
              {item
              ? 
              <ConsultantTabs
                consultant={item}
                survey={survey}
                setTab={setTab}
              />
              : '' }

              
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Consultant;
