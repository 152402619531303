import {useEffect, useState} from 'react';
import {Col, Container, Row, Modal, Image, Nav} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/ShowUserProfile.module.css';
import {
  deleteAccount,
  editProfile,
  getReferalCode,
  getUserData,
} from './Auth.service';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  FaCamera,
  FaCheckCircle,
  FaCopy,
  FaPlusSquare,
  FaShare,
  FaShareAlt,
  FaSignOutAlt,
} from 'react-icons/fa';
import {Language} from './interface/ILanguages';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {insertLanguages} from '../../store/LanguageSlice';
import {useNavigate} from 'react-router-dom';
import {colors} from '../../constants/colors';
import toast, {Toaster} from 'react-hot-toast';
import UserSessions from '../Sessions/UserSessions';
import PrivateSessionsList from '../Booking/PrivateSessionsList';
import ConsultantProfileCourses from '../Consultants/components/ConsultantProfileCourses';
import {getUserAvatar, isUserLoggedIn} from '../../auth/utiles';
import {NavLink} from 'react-router-dom';
import {setLoading} from '../../store/userSlice';
import UserSurveyList from '../Survey/UserSurveyList';
import UserCertificates from '../Courses/UserCertificates';
import MyCourses from '../Consultants/components/MyCourses';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserFamily from './UserFamily';
import UserTasks from './UserTasks';
import { MdOutlineSegment } from 'react-icons/md';
import { getUserCurrency } from '../../helpers/getUserCurrency';
import UserEvents from './UserEvents';
import { getDynamicLinkDomain } from '../../helpers/getDynamicLinkDomain';
import ShareIcon from '../../components/share-icon/ShareIcon';
import CustomRadio from '../../UI/custom-radio/CustomRadio';
import CustomSelectRadio2 from '../../UI/custom-select-radio2/CustomSelectRadio2';
import SecondaryBlueButton from '../../UI/secondary-blue-button/SecondaryBlueButton';


const ShowUserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const allLanguages = useAppSelector(state => state.language.languages);
  const [disableInputs, setdisableInputs] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [birth, setBirth] = useState('');
  const [gender, setGender] = useState(1);
  const [refCode, setRefCode] = useState('');
  const [avatar, setAvatar] = useState('');
  const [languageSelected, setLanguageSelected] = useState<Language[]>([]);
  const [imagePreview, setImagePreview] = useState();
  const [errors, setErrors] = useState<{[key: string]: string}>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [wallet, setWallet] = useState('');
  const userAvatar = getUserAvatar();
  const userCurrency = getUserCurrency()

  const queryParams = new URLSearchParams(window.location.search);
    

  const [defaultActiveTab, setDefaultActiveTab] = useState(queryParams.get('tab') ?? 'first' );
  

  const isLoggedIn = isUserLoggedIn();

  const id = localStorage.getItem('id');

  const ICON_SIZE = 20;

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setAvatar(e.target.result);
        setImagePreview(file);
      };

      reader.readAsDataURL(file);
    }
  };

  const options = [
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'},
  ];

  const LangSelectedCheck = (i: number): boolean => {
    if (languageSelected.find(item => item.id == i)) return true;
    else return false;
  };
  useEffect(() => {

    dispatch(insertLanguages());
    getData(false);
    getRef();
  }, []);

  const getData = async (redirectPage = false) => {
    let result = await getUserData();
    console.log('result.response', result.response);
    if (result.response.status) {
      setName(result.response.user.username);
      setEmail(result.response.user.email);
      setBirth(result.response.user.birth_date);
      setGender(result.response.user.gender);
      setAvatar(result.response.user.avatar);
      setLanguageSelected(result.response.user.languages);
      setWallet(result.response.user.wallet_balance);
      //change image profile in local storage after edit profile
      if (redirectPage) {
        localStorage.setItem('avatar', result.response.user.avatar);
        dispatch(setLoading(false));
      }
    }
  };

  const getRef = async () => {
    let result = await getReferalCode();
    if (result.response.status) {
      setRefCode(result.response.referer_code);
      createDynamicLink(result.response.referer_code)
    }
  };

  const handleCopyClick = () => {
    toast.success('copied');
    // Create a temporary input element to copy the value
    const tempInput = document.createElement('input');
    tempInput.value = url;
    document.body.appendChild(tempInput);

    // Select and copy the value
    tempInput.select();
    document.execCommand('copy');
    
    // Remove the temporary input
    document.body.removeChild(tempInput);
  };

  const logout = () => {
    localStorage.removeItem('Consid');
    localStorage.removeItem('stars');
    localStorage.removeItem('id');
    localStorage.removeItem('switch');
    localStorage.removeItem('isCons');
    localStorage.removeItem('lang');
    localStorage.removeItem('name');
    localStorage.removeItem('logged');
    localStorage.removeItem('avatar');
    localStorage.removeItem('ratingStars');
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    localStorage.removeItem('feedBack');
    if(localStorage.getItem('i18nextLng') ){
      localStorage.removeItem('i18nextLng');
    }
    localStorage.removeItem('distenationRoute');
    localStorage.removeItem('userRefCode');
    localStorage.removeItem('solveQuiz')
    
    
    
    window.location.replace('/');
  };

  const Apply = async () => {
    dispatch(setLoading(true));
    let error: {[key: string]: string} = {};
    let y = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // let phoneCheck =
    //   /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    // if (
    //   phoneCheck.test(
    //     `${this.state.phone_code}${this.containZero(this.state.phone)}`
    //   ) == false &&
    //   this.state.phone != "" &&
    //   this.state.phone != null
    // )
    //   errors.phone = I18n.t("wrongphone");

    if (name == '') error.name = 'Name Invalid';

    if (email == '') error.email = 'mailvalid';
    else if (y.test(email) == false) error.email = 'Wrong Email';
    if (languageSelected.length == 0) error.lan = 'Wrong Languages';

    setErrors(error);
    if (Object.keys(error).length === 0) {
      let data = new FormData();
      data.append('_method', 'put');
      data.append('username', name);
      data.append('birth_date', birth);
      data.append('email', email);
      data.append('gender', String(gender));
      // data.append("phone", phone);
      // data.append("phone_code", `${phone_code}`);

      languageSelected.map((i, index) => {
        data.append(`language_id[${index}]`, String(i.id));
      });

      if (imagePreview) data.append('avatar', imagePreview);
      console.log('dataaa3dit', data);
      //   language_id.map((i,index)=>{
      //  data[`language_id[${index}]`]=i=='english'?1:2
      //  })
      // data.append('firebase_token', 'firebase_token');
      let result = await editProfile(data);
      if (result.response) {
        getData(true);
        dispatch(setLoading(true));
        setdisableInputs(true)
      }

      //

      // this.getUser(true);
    }
  };
  const deleteAccFN = async () => {
    let result = await deleteAccount();
    if (result.response) {
      localStorage.removeItem('Consid');
      localStorage.removeItem('stars');
      localStorage.removeItem('id');
      localStorage.removeItem('switch');
      localStorage.removeItem('isCons');
      localStorage.removeItem('lang');
      localStorage.removeItem('name');
      localStorage.removeItem('logged');
      localStorage.removeItem('avatar');
      localStorage.removeItem('ratingStars');
      localStorage.removeItem('email');
      localStorage.removeItem('token');
      localStorage.removeItem('feedBack');
      if(localStorage.getItem('i18nextLng') ){
        localStorage.removeItem('i18nextLng');
      }
      localStorage.removeItem('userRefCode');
      localStorage.removeItem('solveQuiz')

      window.location.href = '/login'
    }
  };
  const handleSelect = (key: string | null) => {
    navigate({ search: `?tab=${key}` });
  };

  const toggleFilter = () => {
    document.getElementById('userProfileSideBar')?.classList.toggle('hidden-filter'); 
  }

  const hideSideBar = () => {
    document.getElementById('userProfileSideBar')?.classList.remove('hidden-filter'); 
  }

  const [url, setUrl] = useState('https://mataa3.page.link');

  const createDynamicLink = async (code:any) => {
    const requestBody = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://mataa3.page.link',
        link: `${getDynamicLinkDomain()}/?type=referralCode&model_id=${code}`,
        androidInfo: {
          androidPackageName: 'com.together.mata3',
        },
        iosInfo: {
          iosBundleId: 'org.Mata3.app',
        },
      },
    };

    try {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyASCbEH83P9EbeYHrL7ZHtyjqOpmYkOXQE`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        },
      );

      const data = await response.json();
      console.log('dynamic link,', data);
      // return the short link
      setUrl(data.shortLink);
    } catch (error) {
      console.error('Error creating dynamic link: ', error);
      throw error;
    }
  };


  const handleParentClick = () => {
    // Find the element with the class 'shareDropdown'
    const shareDropdownElement = document.querySelector('.shareDropdownBtn');
    if (shareDropdownElement) {
      // Create a new click event
      const event = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      // Dispatch the event to simulate a click
      shareDropdownElement.dispatchEvent(event);
    }
  };

  

  return (
    <div className={`position-relative`}>
      <Toaster position="top-right" />
      <div
        className={`${classes.ConsultantPages} consultant-pages pt-5`}
        style={{background: 'var(--babyBlue)'}}>
        <Container>
          <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={defaultActiveTab}
          onSelect={(key: string | null) => handleSelect(key)}
          >
            <Row className='justify-content-center'>
              <Col sm={12}>
                {/*
                <div className="top-head-sidebar d-flex align-items-center justify-content-between">
                  <div className='d-flex align-items-center'>
                    <div>
                      <img
                        src={
                          avatar
                            ? avatar
                            : userAvatar == 'null'
                            ? `/icons/manHolder.png`
                            : userAvatar!
                        }
                        style={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          marginRight: '15px',
                        }}
                      />
                    </div>
                    <div className="d-flex" style={{flexDirection: 'column'}}>
                      <h5 className="mt-0 mb-1">{name}</h5>
                      <p
                        className="m-0"
                        style={{color: 'gray', lineBreak: 'anywhere'}}>
                        {email}
                      </p>
                    </div>
                  </div>
                  <MdOutlineSegment size={35} onClick={toggleFilter} className='show-sidebar-icon' />
                </div>
                */}
                <div className='user-profile-side-bar' id="userProfileSideBar" >
                  {/*
                  <div>
                    <Row className="your-balance">
                      <Col sm={8} className="title">
                        Your Balance
                      </Col>
                      <Col sm={4} className="value">
                        {wallet} {userCurrency}
                      </Col>
                    </Row>
                  </div>
                  */}
                  <Nav variant="pills" className="all-user-profile-tabs justify-content-center mb-4">
                    <Nav.Item onClick={ () => { hideSideBar() } }>
                      <Nav.Link eventKey="first">Account Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={ () => { hideSideBar() } }>
                      <Nav.Link eventKey="family">My Family</Nav.Link>
                    </Nav.Item>
                    
                    
                    {/*
                    <Nav.Item onClick={ () => { hideSideBar() } }>
                      <Nav.Link eventKey="tasks">My Tasks</Nav.Link>
                    </Nav.Item>
                    */}
                    
                    
                    <Nav.Item onClick={ () => { hideSideBar() } }>
                      <Nav.Link eventKey="sessions">My Sessions</Nav.Link>
                    </Nav.Item>

                    {/*  
                    <Nav.Item onClick={ () => { hideSideBar() } }>
                      <Nav.Link eventKey="events">My Events</Nav.Link>
                    </Nav.Item>
                    */}
                    

                    {/*
                    <Nav.Item onClick={ () => { hideSideBar() } }>
                        <Nav.Link eventKey="requests">My Requests</Nav.Link>
                    </Nav.Item>
                    */}

                    <Nav.Item onClick={ () => { hideSideBar() } }>
                        <Nav.Link eventKey="questionnaires">My Questionnaires</Nav.Link>
                    </Nav.Item>
                      
                    
                    <Nav.Item onClick={ () => { hideSideBar() } }>
                        <Nav.Link eventKey="courses">My Courses</Nav.Link>
                    </Nav.Item>

                    <Nav.Item onClick={ () => { hideSideBar() } }>
                        <Nav.Link eventKey="certificaties">My Certificates</Nav.Link>
                    </Nav.Item>

                    <Nav.Item onClick={ () => { hideSideBar() } }>
                      {isLoggedIn && (
                        <div onClick={() => setShowDeleteModal(true)}>
                          <NavLink to="#" className="nav-link border-0">
                            <span style={{color: '#000'}}>Delete Account</span>
                          </NavLink>
                        </div>
                      )}
                    </Nav.Item>
                      
                    <Nav.Item onClick={ () => { hideSideBar() } }>
                      {isLoggedIn && (
                        <div onClick={() => setShowLogoutModal(true)}>
                          <NavLink to="#" className="nav-link border-0">
                            <span style={{color: '#000'}}>Logout</span>
                          </NavLink>
                        </div>
                      )}
                    </Nav.Item>

                    
                  </Nav>
                </div>
              </Col>
              <Col sm={9}>
                <Tab.Content className='bg-white p-md-3'>
                  <Tab.Pane eventKey="first">
                    <Container className={classes.FormContainer}>
                      
                      <Row className="d-flex justify-content-between">
                        <Col xs={12}>
                          <div>
                            <h3
                              className={`fontWeight-bold ${classes.HeaderTitle} text-center mt-4`}>
                              Your Profile
                            </h3>

                            

                            <div className="mt-5 avatar-uploader text-center">
                              <Image
                                src={
                                  avatar
                                    ? avatar
                                    : userAvatar == 'null'
                                    ? `/icons/manHolder.png`
                                    : userAvatar!
                                }
                                alt="Avatar Preview"
                                roundedCircle
                                width={88}
                                height={88}
                              />
                              <label
                                htmlFor="avatar-input"
                                style={{
                                  position: 'relative',
                                  width: 'fit-content',
                                  margin: 'auto',
                                  padding: '10px',
                                  display: 'block',
                                  color: '#fff',
                                  cursor: 'pointer',
                                }}>
                                {!disableInputs && (
                                  <div className="default-avatar">
                                    <div
                                      style={{
                                        background: '#fff',
                                        border:'1px solid var(--semiPurple)',
                                        color:'var(--semiPurple)',
                                        padding: '7px 17px',
                                        display: 'flex',

                                      }} className='rounded d-flex align-items-center' >
                                      <img src="/basil_camera-outline.svg" />
                                      <p style={{
                                        margin: '0 5px',
                                        fontSize: '15px',
                                        fontWeight: '600'

                                      }}>
                                        Change Picture
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </label>
                              <input
                                type="file"
                                id="avatar-input"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{display: 'none'}}
                                disabled={disableInputs}
                              />
                            </div>

                            <Row className="your-balance">
                              <Col sm={8} className="title">
                                Your Balance
                              </Col>
                              <Col sm={4} className="value">
                                {wallet} {userCurrency}
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <div className="mt-3 mb-3">
                                  <label>Name</label>
                                  <input
                                    type="text"
                                    disabled={disableInputs}
                                    className={`form-control m-0 ${classes.FormInput}`}
                                    required
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                  />
                                  {errors?.name && (
                                    <p style={{color: colors.darkRed}}>
                                      {errors.name}
                                    </p>
                                  )}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mt-3 mb-3">
                                  <label>Email Address</label>
                                  <input
                                    type="email"
                                    disabled={true}
                                    className={`form-control m-0 ${classes.FormInput}`}
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                  />
                                </div>
                              </Col>

                              {/*    
                              <div className="mt-3 mb-3">
                                <label>Date Of Birth</label>
                                <input
                                  type="date"
                                  disabled={disableInputs}
                                  className={`form-control m-0 ${classes.FormInput}`}
                                  placeholder="DD-mm-yyyy"
                                  required
                                  value={birth}
                                  onChange={e => setBirth(e.target.value)}
                                />
                              </div>
                              */}

                                  
                              <Col xs={{span: 12}} md={{span: 12}}>
                                <div className="mt-3 mb-3">
                                  <label className="mb-2">
                                    Gender<span style={{color: 'red'}}>* </span>
                                  </label>
                                  <div className='d-md-flex gap-4'>
                                    <div className='mb-1' >
                                    

                                      <CustomSelectRadio2
                                        name="gender"
                                        text="Male"
                                        forId={'Male'}
                                        disabled={false}
                                        value={1}
                                        onRadioChange={(value: string) =>
                                        setGender(1)
                                        }
                                        checked={gender == 1}
                                      />
                                    
                                    </div>
                                    <div className='mb-1' >
                                    <CustomSelectRadio2
                                        name="gender"
                                        text="Female"
                                        forId={'Female'}
                                        disabled={false}
                                        value={2}
                                        onRadioChange={(value: string) =>
                                        setGender(2)
                                        }
                                        checked={gender == 2}
                                      />
                                    </div>
                                    {/*
                                    <div className='mb-1' >
                                      <CustomRadio
                                        text="Prefer not to disclose"
                                        checked={gender == 3}
                                        onClick={() => setGender(3)}
                                      />
                                    </div>
                                    */}
                                  </div>
                                </div>
                              </Col>

                              
                              <div className="mt-3 mb-3">
                                <label className="mb-2">
                                  Language<span style={{color: 'red'}}>* </span>
                                </label>
                                <Dropdown autoClose="outside">
                                  <Dropdown.Toggle
                                    disabled={disableInputs}
                                    color="red"
                                    className={`form-control m-0 ${classes.Drop}`}></Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {allLanguages.map(i => {
                                      return (
                                        <Dropdown.Item
                                          onClick={() => {
                                            if (LangSelectedCheck(i.id)) {
                                              setLanguageSelected(
                                                languageSelected.filter(
                                                  items => items.id != i.id,
                                                ),
                                              );
                                            } else {
                                              setLanguageSelected([
                                                ...languageSelected,
                                                i,
                                              ]);
                                            }
                                          }}>
                                          <Row className="d-flex justify-content-between">
                                            <Col md={11}>
                                              <div> {i.name}</div>
                                            </Col>
                                            {LangSelectedCheck(i.id) && (
                                              <Col md={1}>
                                                <FaCheckCircle
                                                  color="#FA5456"
                                                  size={20}
                                                />
                                              </Col>
                                            )}
                                          </Row>
                                        </Dropdown.Item>
                                      );
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>

                              <div
                                className="d-flex"
                                style={{flexWrap: 'wrap'}}>
                                {languageSelected.map(i => {
                                  return (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        borderRadius: 10,
                                        padding: 8,
                                        marginBlock: 10,
                                        width: 'fit-content',
                                        margin: '10px 5px',
                                        background: '#E8E8E8',
                                      }}>
                                      <Col>{i.name}</Col>
                                      <Col
                                        onClick={() => {
                                          if (!disableInputs) {
                                            setLanguageSelected(
                                              languageSelected.filter(
                                                items => items.id != i.id,
                                              ),
                                            );
                                          }
                                        }}
                                        style={{
                                          alignItems: 'flex-end',
                                          textAlign: 'right',
                                          margin: '0 0 0 10px',
                                        }}>
                                        X
                                      </Col>
                                    </div>
                                  );
                                })}
                              </div>
                              {errors?.lan && (
                                <p style={{color: colors.darkRed}}>
                                  {errors.lan}
                                </p>
                              )}
                              <div className="mt-3 mb-3">
                                <label>Referral Link</label>
                                <div className="d-flex align-items-center" >
                                  <div className={`${classes.RefCode} d-flex align-items-center flex-grow-1 bg-white`} onClick={handleCopyClick}>
                                    <img src="/copy-ico.svg" alt="img" width={'25'} />
                                    <span style={{ marginLeft : '10px' }}>Copy Link</span>
                                  </div>
                                  <div className={`${classes.RefCode} d-flex flex-grow-1 bg-white`}
                                  onClick={handleParentClick}
                                  >
                                    <ShareIcon url={url} hasInvite={true} hasIcon={true} text={'Share Link'} fullWidth={true} />
                                    
                                  </div>
                                
                                </div>
                              </div>
                            </Row>

                            <div
                              className="mt-4 mb-2"
                              onClick={() => {
                                disableInputs
                                  ? setdisableInputs(false)
                                  : Apply();
                              }}>
                              <MainButton
                                text={disableInputs ? 'Edit Profile' : 'Confirm'}
                              />
                            </div>

                            
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="family">
                    <h3 className="mb-4">My Family</h3>
                    <UserFamily />
                  </Tab.Pane>
                  
                  
                  <Tab.Pane eventKey="tasks">
                    <h3 className="mb-4">My Tasks</h3>
                    <UserTasks />
                  </Tab.Pane>
                  
                  
                  <Tab.Pane eventKey="sessions">
                    <h3 className="mb-4">My Sessions</h3>
                    <UserSessions />
                  </Tab.Pane>

                  <Tab.Pane eventKey="events">
                    <h3 className="mb-4">My Events</h3>
                    <UserEvents />
                  </Tab.Pane>

                  <Tab.Pane eventKey="requests">
                    <h3>My Requests</h3>
                    <PrivateSessionsList isCons={0} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="questionnaires">
                    <h3>My Questionnaires</h3>
                    <UserSurveyList />
                  </Tab.Pane>
                  
                  <Tab.Pane eventKey="courses">
                    <h3 className="mb-4">Courses</h3> 
                    <MyCourses />
                   
                    
                  </Tab.Pane>

                  <Tab.Pane eventKey="certificaties">
                    <h3 className="mb-4">Certificates</h3> 
                    <UserCertificates />
                  </Tab.Pane>
                    
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <img src="/icon-park_delete-five.svg" className='mb-4' />
            <h3 className='font-20 font-weight-700'>Delete Account !</h3>
            <h5 className="w-100 mt-2 font-16">Are you sure you want to delete account ?</h5>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Row className="w-100">
            <Col onClick={() => {deleteAccFN()} }>
              <SecondaryBlueButton text={"Yes, I'm Sure"}  />
            </Col>
            <Col onClick={() => setShowDeleteModal(false)}>
              <MainButton
                text={'Cancel'}
                
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <img src="/majesticons_logout.svg" className='mb-4' />
            <h3 className='font-20 font-weight-700'>Come Back Soon !</h3>
            <h5 className="w-100 mt-2 font-16">Are you sure you want to log out ?</h5>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Row className="w-100">
            <Col onClick={()=>{logout()}}>
              <SecondaryBlueButton text={"Yes, I'm Sure"}  />
            </Col>
            <Col onClick={() => setShowLogoutModal(false)} >
              <MainButton
                text={'Cancel'}
                
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShowUserProfile;
