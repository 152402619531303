import './style/Consultants.css';
import {Container, Row, Col} from 'react-bootstrap';
import ConsultantFilter from './components/ConsultantFilter';
import ConsultantsList from './components/ConsultantsList';
import {Fragment, useEffect, useState} from 'react';
import logo from './style/header.png';
import {getConsultantsList} from './Consultants.service';
import {useParams,useNavigate,useLocation} from 'react-router-dom';
import { isUserLoggedIn } from '../../auth/utiles';
import { useTranslation} from 'react-i18next';
import { NavLink } from 'react-router-dom';
import SearchInput from '../../components/search-input/SearchInput';
import { getDynamicLinkDomain } from '../../helpers/getDynamicLinkDomain';
import ShareIcon from '../../components/share-icon/ShareIcon';
import ShareContent from '../../components/share-content/ShareContent';

const Teachers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let type = 'teachers';
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [name, setName] = useState<string>('');
  const [languagesSelected, setLanguagesSelected] = useState<number[]>([]);
  const [fieldsSelected, setFieldsSelected] = useState<{[key: string]: any[]}>(
    {},
  );
  const [selectedDays, setSelectedDays] = useState<any[] | null>([]);
  const [selectedTime, setSelectedTime] = useState<number | null>();
  const [typesSelected, setTypesSelected] = useState<string[]>([]);

  const [url, setUrl] = useState('https://mataa3.page.link');

  useEffect(() => {
    //if( tracking ){
      createDynamicLink()
    //}
  }, []);

  const createDynamicLink = async () => {
    const requestBody = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://mataa3.page.link',
        link: `${getDynamicLinkDomain()}/?type=ExpertList`,
        androidInfo: {
          androidPackageName: 'com.together.mata3',
        },
        iosInfo: {
          iosBundleId: 'org.Mata3.app',
        },
      },
    };

    try {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyASCbEH83P9EbeYHrL7ZHtyjqOpmYkOXQE`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        },
      );

      const data = await response.json();
      console.log('dynamic link,', data);
      // return the short link
      setUrl(data.shortLink);
    } catch (error) {
      console.error('Error creating dynamic link: ', error);
      throw error;
    }
  };

  useEffect(() => {
    const Logged = isUserLoggedIn();
    if( Logged ){
      getConsultants();
    }else{
      navigate(`/login`, {state: {location: location.pathname }})
    }
  }, [
    page,
    name,
    type,
    languagesSelected,
    fieldsSelected,
    selectedTime,
    selectedDays,
    typesSelected,
  ]);

  const getConsultants = () => {
    getConsultantsList({
      page: page,
      load: false,
      type:
        typesSelected.length != 0
          ? typesSelected
          : ['consultant', 'organization' , 'teacher' ]
          ? ['consultant', 'organization' , 'teacher' ]
          : ['consultant', 'organization' , 'teacher' ],
      name: name,
      language: languagesSelected,
      fields: fieldsSelected,
      selectedDays: selectedDays,
      selectedTime: selectedTime,
    });
  };

  const handleSearch = (searchTerm: any) => {
    setPage(1);
    setName(searchTerm)
  };

  return (
    <Fragment>
      <div className="contact-header d-flex justify-content-center align-items-center">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xs={6} className="d-flex justify-content-center align-items-center">
                <div className="breadcrumb d-flex justify-content-center">
                  <h3 className="breadcrumb-header">Mentors</h3>
                  <div className="breadcrumb-details text-center">
                    <span>Home</span> <span> - </span>{' '}
                    <span>Mentors</span>
                  </div>
                </div>
              </Col>

              
            </Row>
          </Container>
        </div>

      <div className="content-details pt-5">
          <Container>
            <Row>
              <Col md="12" className='mb-4 p-md-0'>
                <ShareContent url={url} hasInvite={true} />
              </Col>
            </Row>

            <Row className="justify-content-between top-list-filters align-items-center">
              <Col md="8">
              <ConsultantFilter
              type={type}
              setLang={setLanguagesSelected}
              setField={setFieldsSelected}
              setDays={setSelectedDays}
              setTimes={setSelectedTime}
              setTypes={setTypesSelected}
            />
              </Col>

              <Col md="4">
                <div className="row courses-top-head justify-content-between align-items-center">
                  <SearchInput onSearch={handleSearch} />
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between list-filter align-items-center">

              <Col md={12}>
                <ConsultantsList
                  setPage={page => setPage(page)}
                  page={page}
                  submitTitle={name => {
                    setName(name);
                  }}
                />
              </Col>
            </Row>
          </Container>
        </div>
    </Fragment>
  );
};

export default Teachers;
