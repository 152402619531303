import {Col, ProgressBar, Row} from 'react-bootstrap';
import RateStars from '../../../UI/rate-stars/RateStars';
import Comments from '../../Courses/components/Comments';
import '../style/ConsultantFeedback.css';
import {ConsultantFeedBacksProp} from '../interfaces/IConsultants';

const ConsultantFeedback = ({
  rating_stars,
  feedback,
  ratings,
}: ConsultantFeedBacksProp) => {
  let rate = 0;

  rate =
    rating_stars?.five?.rate +
    rating_stars?.four?.rate +
    rating_stars?.three?.rate +
    rating_stars?.two?.rate +
    rating_stars?.one?.rate;

  return (
    <div className="mt-0">
      <div className="section-title mb-3"
      style={{
        color: '#A2A2A2',
        fontSize: '14px',
        fontWeight: '400',
      }}
      >
        <img src="/star.svg" alt="img" /> ({rate} reviews ) 
      </div>
      {Object.keys(rating_stars)
        ?.reverse()
        .map((key, index) => {
          const element = rating_stars[key].rate;

          return (
            <Row className="d-flex all-rates justify-content-between">
              <Col xs={{span: 6}} md={{span: 8}}>
                <div className="progress-bar">
                  <ProgressBar
                    now={element && rate > 0 ? (element / rate) * 100 : 0}
                  />
                </div>
              </Col>

              <Col xs={{span: 6}} md={{span: 4}}>
                <div className="d-flex align-items-center">
                  <div className="stars">
                    <RateStars rateValue={5 - index} />
                  </div>

                  <div className="starts-percentage mx-2">
                    {element && rate > 0 ? Math.round((element / rate) * 100) : 0}%
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}

      {feedback?.map(i => (
        <Comments comment={i} />
      ))}
    </div>
  );
};

export default ConsultantFeedback;
