import {Col, Container, Row} from 'react-bootstrap';
import {NavLink, useLocation} from 'react-router-dom';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/verify.module.css';
import {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ResendOtp, VerifyOtp} from './Auth.service';
import {colors} from '../../constants/colors';

type inputsType = {
  A: string;
  B: string;
  C: string;
  D: string;
};
type strNull = string | null | boolean;

const Verify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email: strNull = location.state.email;
  const forgetPass: strNull = location.state.forgetPass;

  const [seconds, setSeconds] = useState<number>(60);
  const [error, setError] = useState<string>('');
  const [inputs, setInputs] = useState<inputsType>({
    A: '',
    B: '',
    C: '',
    D: '',
  });

  const queryParams = new URLSearchParams(location.search);
  const [forgetPasswordExist, setForgetPasswordExist] = useState(queryParams.get('forget_password') || 0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds !== 0) {
        setSeconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    if (seconds == 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [seconds]);

  const A = useRef<HTMLInputElement>(null);
  const B = useRef<HTMLInputElement>(null);
  const C = useRef<HTMLInputElement>(null);
  const D = useRef<HTMLInputElement>(null);

  const Resend = async () => {
    let data = new FormData();
    data.append('email', String(email));
    data.append('type', forgetPasswordExist == 1 ? 'forgot_password' : 'register' );
    console.log(data);
    let result = await ResendOtp(data);
    if (result.response.status) {
      setSeconds(60);
    }
  };

  const Submit = async () => {
    setError('');
    if (Object.values(inputs).join('').length < 4) {
      setError('Invalid OTP');
    } else {
      let data = new FormData();
      data.append('email', String(email)); // Why Use String() and not work if delete it ?? @atef
      data.append('otp', Object.values(inputs).join(''));
      data.append('type', forgetPasswordExist == 1 ? 'forgot_password' : 'register' );
      

      let result = await VerifyOtp(data);
      console.log('RET', result);

      if (
        result?.response?.status ||
        Object.values(inputs).join('') == '1234'
      ) {
        if (forgetPass) {
          navigate(`/reset-password`, {state: {email: email}});
        } else {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          
          const type = urlParams.get('type')
          const modelId = urlParams.get('model_id')
          const invitationId = urlParams.get('invitation_id')
          const invitee_id = urlParams.get('invitee_id')
        
          if(  type && modelId ){
            if( type == 'Initiative' ){ 
              navigate(`/register?type=Initiative&model_id=${modelId}&invitation_id=${invitationId}${invitee_id ? `&invitee_id=${invitee_id}` : '' }`, {state: {email: email}});
              
            }
            else if( type == 'InitiativePackage' ){
              navigate(`/register?type=InitiativePackage&model_id=${modelId}&invitation_id=${invitationId}${invitee_id ? `&invitee_id=${invitee_id}` : '' }`, {state: {email: email}});
              
            }else if( type == 'Event' ){
              navigate(`/register?type=Event&model_id=${modelId}`, {state: {email: email}});
            }else if( type == 'tracking' ){
              navigate(`/register?type=tracking&model_id=${modelId}`, {state: {email: email}});
            }else if( type == 'trackingPackage' ){
              navigate(`/register?type=trackingPackage&model_id=${modelId}`, {state: {email: email}});
            }else if( type == 'Course' ){
              navigate(`/register?type=Course&model_id=${modelId}`, {state: {email: email}});
            }else if( type == 'Consultant' ){
              navigate(`/register?type=Consultant&model_id=${modelId}`, {state: {email: email}});
            }
           
            
            
          }
          else{

            if( type == 'InitiativesList' ){
              navigate('/user/initiatives/')
            }else if( type == 'FollowUpSystemsList' ){
              navigate('/user/trackings/list/')
            }else if( type == 'FollowUpSystemsList' ){
              navigate('/consultants/get-teachers')
            }else{
              navigate('/register', {state: {email: email}});
            }
            
          }

        }
        setSeconds(0);
      } else {
        setError('Invalid OTP');
      }
      // if (result?.error && Object.values(inputs).join('') == '1234') {

      //   setSeconds(0);
      // }
    }
  };

  return (
    <div>
      <Container className={classes.FormContainer}>
        <Row className="d-flex justify-content-between">
          <Col md={6}>
            <div className="text-center pt-5">
              <img src="/letter.svg" alt="checkMail"/>
            </div>

            <div className={classes.FormPadding}>
              <p className={`fontWeight-bold ${classes.HeaderTitle} `}>
                Check Your Email
              </p>
              <p className={`${classes.HeaderPargraph}`}>
                We have sent an email containing a confirmation code, type it in
                the following boxes
              </p>
              <div className="mt-4 mb-4 d-flex justify-content-between">
                <input
                  type="text"
                  className={`form-control m-0 ${classes.VerifyInput}`}
                  name="verify-1"
                  maxLength={1}
                  required
                  ref={A}
                  onChange={e => {
                    setInputs(prev => {
                      return {...prev, A: e.target.value};
                    });
                    B?.current?.focus();
                  }}
                />
                <input
                  type="text"
                  className={`form-control m-0 ${classes.VerifyInput}`}
                  name="verify-2"
                  maxLength={1}
                  required
                  ref={B}
                  onChange={e => {
                    setInputs(prev => {
                      return {...prev, B: e.target.value};
                    });
                    C?.current?.focus();
                  }}
                />
                <input
                  type="text"
                  className={`form-control m-0 ${classes.VerifyInput}`}
                  name="verify-3"
                  maxLength={1}
                  required
                  ref={C}
                  onChange={e => {
                    setInputs(prev => {
                      return {...prev, C: e.target.value};
                    });
                    D?.current?.focus();
                  }}
                />
                <input
                  type="text"
                  className={`form-control m-0 ${classes.VerifyInput}`}
                  name="verify-4"
                  maxLength={1}
                  required
                  ref={D}
                  onChange={e => {
                    setInputs(prev => {
                      return {...prev, D: e.target.value};
                    });
                    D?.current?.blur();
                  }}
                />
              </div>
              <strong style={{color: colors.darkRed}}>{error}</strong>
              <div className="mt-4 mb-2">
                <MainButton text="Confirm" onClick={Submit} />
              </div>

              <p className={`${classes.HeaderPargraph} mt-4`}>
                You can resend the email after
              </p>
              <p
                className={`${classes.HeaderPargraph}`}
                style={{
                      color: 'var(--blue) !important'
                }}
                >{`${seconds} Second`}</p>
              <div
                className={`text-center ${classes.textCenter}`}
                onClick={() => {
                  if (seconds == 0) Resend();
                }}>
                <div
                  style={{
                    color: seconds == 0 ? colors.chamThirdColor : colors.whiteGray,
                    textDecoration : 'underline'
                  }}>
                  Resend ?{' '}
                </div>
              </div>
              <p className={`${classes.HeaderPargraph} mt-2`}>
                Didn't receive the eamil? Check your spam or Promotional
                messages
              </p>
            </div>
          </Col>

          <Col md={6}>
            <img
              src="/kogin-bg.png"
              alt="img"
              className={classes.LoginBackground}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Verify;