import "./MainButton.css";
import Button from "react-bootstrap/Button";

function MainButton({ text, ...res }) {
  return (
    <Button className="card-button d-flex justify-content-center" variant="primary" {...res}>
      {typeof text === "string" ? (
        text
      ) : (
        <span className="d-flex align-items-center text-white">
          {text.label}
          <img src={text.img} alt={text.alt || "icon"} className="button-icon mx-2" />
        </span>
      )}
    </Button>
  );
}

export default MainButton;
