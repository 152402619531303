import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  useEffect(() => {
    const toggleVisibility = () => {
      setVisible(window.scrollY > 200);
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {visible && (
        
          <img 
          onClick={handleScrollTop}
          className="fixed transition-all"
          src="/chevron-up.svg" alt="img" style={{width: '32px' , right: '3rem' , bottom: '3rem !important' ,  top: 'inherit' , bottom: '2rem' , cursor: 'pointer' }} />
       
      )}
    </>
  );
};

export default ScrollToTop;
