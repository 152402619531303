import {Col, Container, Row, Spinner} from 'react-bootstrap';
import classes from './style/Checkout.module.css';
import MainButton from '../../UI/main-button/MainButton';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {getBalance, setLoading} from '../../store/userSlice';
import {ApplyCoupon, CheckOut} from './Checkout.service';
import toast, {Toaster} from 'react-hot-toast';
import {useNavigate} from 'react-router';
import {getToCart} from '../../store/CartSlice';
import store from '../../store';
import {useLocation} from 'react-router-dom';
import {getUserCurrency} from '../../helpers/getUserCurrency';
const Checkout = () => {
  const userCurrency = getUserCurrency();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cart = useAppSelector(state => state.cart);
  const totalProducts = useAppSelector(state => state.cart.totalProducts);
  //const subTotal = useAppSelector(state => state.cart.totalPrice);
  //const subTotal = parseInt(queryParams.get("total") || "0", 10)
  const subTotal = cart.totalPrice;
  const myBalance = useAppSelector(state => state.user.balance);
  console.log('myBalancemyBalancemyBalance', myBalance);

  // const [loading, setLoading] = useState<boolean>(false);
  const [couponLoading, setCouponLoading] = useState<boolean>(false);

  const [originalWalletBalance, setOriginalWalletBalance] =
    useState<number>(myBalance);
  const [valueUsedOfWallet, setValueUsedOfWallet] = useState<number>(0);

  const [walletUsed, setWalletUsed] = useState<boolean>(false);
  const [couponApplied, setCouponApplied] = useState<boolean>(false);
  const [couponValue, setCouponValue] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<number | null>(null);

  const [finalTotal, setFinalTotal] = useState<number | null>(null);
  const [discount, setDiscount] = useState<number>(0);
  const [couponValidation, setCouponValidation] = useState<string>('');
  const [requestValidation, setRequestValidation] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  useEffect(() => {
    dispatch(getBalance());
  }, []);

  useEffect(() => {
    setOriginalWalletBalance(myBalance);
  }, [myBalance]);

  useEffect(() => {
    Calculate();
  }, [walletUsed, discount, couponApplied]);

  const PlaceOrder = async () => {
    if (paymentMethod == null && walletUsed == false && finalTotal != 0) {
      toast.error('select Pay Method');
    } else if (
      finalTotal != 0 &&
      userCurrency == 'EGP' &&
      paymentMethod == 1 &&
      phone == ''
    ) {
      toast.error('Please Enter Your Phone Number');
    } else {
      if (finalTotal != 0 && paymentMethod == null) {
        toast.error('Please Select Payment Method');
      } else {
        dispatch(setLoading(true));
        try {
          // dispatch(setLoad(true));
          let data = new FormData();
          data.append('isWallet', String(walletUsed ? 1 : 0));
          data.append('is_web', '1');
          data.append(
            'walletAmount',
            String(walletUsed ? valueUsedOfWallet : 0),
          );
          if (userCurrency == 'USD') {
            data.append(
              'payment_method',
              finalTotal == 0
                ? 'wallet'
                : paymentMethod == 1
                ? 'paypal'
                : 'stripe',
            );
          } else {
            data.append(
              'payment_method',
              finalTotal == 0
                ? 'wallet'
                : paymentMethod == 1
                ? 'paymob_mobile_wallet'
                : 'paymob_card',
            );
          }

          if (userCurrency == 'EGP' && paymentMethod == 1) {
            data.append('wallet_mobile_number', phone);
          }

          couponApplied && data.append('code', couponValue);
          cart.data.map((item, index) => {
            data.append(`items[${index}][model_id]`, String(item.model_id));
            data.append(`items[${index}][model_name]`, item.model_name);
            data.append(`items[${index}][user_id]`, String(item.user_id));
          });
          console.log('datasend', data);
          let result = await CheckOut(data);
          console.log('resultresultresultresultresult2', result);

          if (result.error) {
            dispatch(setLoading(false));
            toast.error(result.error.response.data.message);
          } else if (result.response.success) {
            if (result.response.message.includes('paid')) {
              dispatch(setLoading(false));
              store.dispatch(getToCart());
              //navigate('/payment-result?payment_success=1');
              window.location.replace('/payment-result?payment_success=1');
            } else {
              dispatch(setLoading(false));
              window.location.replace(result.response.data);
              // dispatch(setLoad(false));
              // navigation.navigate('ViewWeb', {
              //   url: result.data.data,
              //   type: 'Cart',
              //   paymentDetails: {
              //     totalProducts: totalProducts,
              //     subTotal: subTotal,
              //     discount: discount,
              //     couponApplied: couponApplied,
              //     walletUsed: walletUsed,
              //     loading: loading,
              //     valueUsedOfWallet: valueUsedOfWallet,
              //     finalTotal: finalTotal,
              //     cart: route.params.cart,
              //   },
              // });
            }
          } else {
            toast.error(result.response.msg);
            console.log('eer', result);
            dispatch(setLoading(false));
            // dispatch(setLoad(false));
            setRequestValidation(result.response.msg);
          }
        } catch (e) {
          dispatch(setLoading(false));
          console.log('errrrror5', e);
          // dispatch(setLoad(false));
          console.log(e);
        }
      }
    }
  };
  const ApplyCouponFN = async () => {
    setCouponLoading(true);
    setCouponValidation('');
    try {
      let data = new FormData();
      data.append('code', couponValue);
      data.append('total_price', String(subTotal));
      let result = await ApplyCoupon(data);
      console.log(result.response);

      if (result.response.status) {
        // setLoading(true);
        setCouponApplied(true);
        setDiscount(subTotal - result.response.data);
        // setLoading(false);
        setCouponLoading(false);
      } else {
        setCouponValidation(result.response.msg);
        // setLoading(false);
        setCouponLoading(false);
      }
    } catch (e) {
      setCouponLoading(false);

      // setLoading(false);

      console.log(e);
    }
  };
  const Calculate = () => {
    // setLoading(true);
    let finalTotal = subTotal;
    if (walletUsed) {
      if (myBalance > finalTotal) {
        finalTotal = 0;
        couponApplied
          ? setValueUsedOfWallet(subTotal - discount)
          : setValueUsedOfWallet(subTotal);
      } else {
        couponApplied
          ? setValueUsedOfWallet(
              originalWalletBalance > subTotal - discount
                ? subTotal - discount
                : originalWalletBalance,
            )
          : setValueUsedOfWallet(originalWalletBalance);
        finalTotal = finalTotal - myBalance;
      }
    }
    if (couponApplied) {
      if (finalTotal - discount <= 0) {
        finalTotal = 0;
      } else {
        finalTotal = finalTotal - discount;
      }
    }

    console.log('xxxxxxx1111', finalTotal);

    setFinalTotal(finalTotal);
    // setLoading(false);
  };
  return (
    <Container fluid className={`${classes.ChackoutContainer}`}>
      <Toaster position="top-right" />
      <Row>
        <h1 className="text-center p-0 mb-3">Checkout</h1>
        <Col md={8} className="d-flex align-items-center offset-md-2">
          <div className={`w-100 ${classes.CheckoutDetails} `}>
            <div className={`w-100 ${classes.PromoCodeContainer} `}>
              <h4>
                Promo Code <img src="/promocode.jpeg" alt="promocode" />{' '}
              </h4>
              <p className={`w-100 ${classes.Hint} `}>
                * If you have a promo code and need to take its discount please
                insert it in the field and click apply
              </p>
              <div className={`${classes.applyPromoCodeContainer} mb-2`}>
                <input
                  type="text"
                  className={`${classes.applyPromoCodeInput} form-control mt-0 `}
                  value={couponValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCouponValue(e.target.value);
                    console.log(
                      'e.target.value,e.target.value',
                      e.target.value,
                    );
                  }}
                />
                <button
                  className={
                    couponValue == ''
                      ? classes.applyPromoCodeBtnNOTApplied
                      : classes.applyPromoCodeBtnApplied
                  }
                  onClick={() => {
                    if (couponApplied) {
                      setCouponApplied(false);
                      setDiscount(0);
                      setCouponValidation('');
                      setCouponValue('');
                    } else {
                      couponValue !== '' && ApplyCouponFN();
                    }
                  }}>
                  {couponLoading ? (
                    <Spinner color="white" size="sm" />
                  ) : couponApplied ? (
                    'Remove'
                  ) : (
                    'Apply'
                  )}
                </button>
                <div>{couponValidation}</div>
              </div>
            </div>

            <div className={`w-100 ${classes.WalletContainer} `}>
              <h4>
                Wallet <img src="wallet.jpeg" alt="promocode" />{' '}
              </h4>
              <div className="d-flex justify-content-between mt-3">
                <p>
                  <span className={`${classes.WalletBalance} `}>
                    {myBalance} {userCurrency}
                  </span>
                  {walletUsed && (
                    <span>
                      Remaining Balance{' '}
                      <span className={`${classes.WalletBalance} `}>
                        {originalWalletBalance - valueUsedOfWallet}{' '}
                        {userCurrency}
                      </span>
                    </span>
                  )}
                </p>

                <div>
                  <div className={`${classes.toggleWalletContainer}`}>
                    <input
                      type="checkbox"
                      id="toggle-wallet"
                      onChange={i => {
                        setWalletUsed(i.target.checked);
                      }}
                    />
                    <label id="toggle-wallet"></label>
                  </div>
                </div>
              </div>
              <p className={`w-100 ${classes.Hint} `}>
                * You can use your wallet balance for buying by enabling the
                button
              </p>
            </div>
            <div className={`w-100 ${classes.PaymentMethodContainer} `}>
              <h4>Payment Method</h4>

              {userCurrency == 'USD' ? (
                <div
                  className={`${classes.CheckBoxesContainer} mt-4 d-flex justify-content-between`}>
                  <p
                    onClick={() => setPaymentMethod(2)}
                    className={`${classes.CheckBox} ${classes.Visa} mb-1 visa`}>
                    <input
                      type="radio"
                      id="visa"
                      name="radio-group"
                      checked={paymentMethod == 2}
                    />
                    <label htmlFor="visa">
                      <img src="visa.jpeg" alt="visa" />
                      <img src="master.jpeg" alt="master" />
                      <img src="discover.jpeg" alt="discover" />
                      <img src="american-express.png" alt="american-express" />
                    </label>
                  </p>
                  <p
                    onClick={() => setPaymentMethod(1)}
                    className={`${classes.CheckBox} ${classes.Paypal} mb-1`}>
                    <input
                      type="radio"
                      id="paypal"
                      name="radio-group"
                      checked={paymentMethod == 1}
                    />
                    <label htmlFor="paypal">
                      <img src="paypal.png" alt="paypal" />
                    </label>
                  </p>
                </div>
              ) : (
                <div>
                  <div
                    className={`${classes.CheckBoxesContainer} mt-4 d-flex justify-content-between`}>
                    <p
                      onClick={() => setPaymentMethod(2)}
                      className={`${classes.CheckBox} ${classes.Visa} mb-1 visa`}>
                      <input
                        type="radio"
                        id="visa"
                        name="radio-group"
                        checked={paymentMethod == 2}
                      />
                      <label htmlFor="visa">
                        {/*<img src="/icons/paymob-card.png" alt="visa"
                      style={{
                        width: '150px',
                        height: 'auto'
                      }}
                      />
                      */}
                        <img src="visa.jpeg" alt="visa" />
                        <img src="master.jpeg" alt="master" />
                      </label>
                    </p>
                    
                    <p
                      onClick={() => setPaymentMethod(1)}
                      className={`${classes.CheckBox} ${classes.Paypal} mb-1`}>
                      <input
                        type="radio"
                        id="paypal"
                        name="radio-group"
                        checked={paymentMethod == 1}
                      />
                      <label htmlFor="paypal">
                        
                        <div
                          style={{
                            marginTop: '10px',
                          }}>
                          Mobile Wallet
                        </div>
                      </label>
                    </p>
                    
                  </div>

                  {paymentMethod == 1 ? (
                    <input
                      type="text"
                      className={`${classes.applyPromoCodeInput} form-control mt-4 `}
                      value={phone}
                      placeholder="Please enter your phone number"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPhone(e.target.value);
                        console.log(
                          'e.target.value,e.target.value',
                          e.target.value,
                        );
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              )}
              <div className="d-flex justify-content-between mt-3"></div>
            </div>

            <div className={`w-100 ${classes.SummaryContainer} `}>
              <h4>Order Summary</h4>
              <div className={`${classes.CheckBoxesContainer} mt-4`}>
                <div className="d-flex justify-content-between mb-3">
                  <h6>Number Of Products</h6>
                  <h5 className="main-title">{cart.totalProducts}</h5>
                </div>

                <div className="d-flex justify-content-between">
                  <h6>Sub Total</h6>
                  <h5 className="main-title">
                    {cart.totalPrice} {userCurrency}
                  </h5>
                </div>
                {discount !== 0 && (
                  <div className="d-flex justify-content-between">
                    <h6>Discount</h6>
                    <h5 className="main-title">
                      {discount} {userCurrency}
                    </h5>
                  </div>
                )}
                {walletUsed && (
                  <div className="d-flex justify-content-between">
                    <h6>Wallet</h6>
                    <h5 className="main-title">
                      {valueUsedOfWallet} {userCurrency}
                    </h5>
                  </div>
                )}
                <hr />

                <div className="d-flex justify-content-between mb-3">
                  <h6>Total Order</h6>
                  <h5 className="main-title">
                    {finalTotal} {userCurrency}
                  </h5>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <MainButton text="Place Order" onClick={PlaceOrder} />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Checkout;
