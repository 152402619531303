import avatarImg from '../style/profile.png';
import '../style/Comments.css';
import RateStars from '../../../UI/rate-stars/RateStars';
import {Container} from 'react-bootstrap';
import {FeedBackProp} from '../interfaces/ICourses';

const CourseComments = ({comment}: FeedBackProp) => {
  return (
    
      <div className="comment-item d-flex mt-5 justify-content-between border rounded p-1 p-md-4">
        <div className="d-flex">
          <div className="comment-image d-flex justify-content-center">
            <img src={comment?.user?.avatar ?? '/avatar2.png'} alt="comment-img" style={{borderRadius : '50%'}} />
          </div>
          <div className="mx-3 comment-details">
            <h4>{comment?.user?.username}</h4>
            <p className='my-2 d-flex align-items-center gap-1 font-weight-600'>
              {/*<RateStars rateValue={comment.rating} />*/}
              <img src="/star.svg" alt="img" /> {comment.rating}
            </p>
            <p className='m-0'>{comment.comment}</p>
          </div>
        </div>
      </div>
  
  );
};

export default CourseComments;
