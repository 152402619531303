import {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {FaEye, FaCheckCircle} from 'react-icons/fa';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/register.module.css';
import CustomRadio from '../../UI/custom-radio/CustomRadio';
import Dropdown from 'react-bootstrap/Dropdown';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {insertLanguages} from '../../store/LanguageSlice';
import {Language} from './interface/ILanguages';
import {useLocation, useNavigate} from 'react-router-dom';
import {RegisterAccount} from './Auth.service';
import {colors} from '../../constants/colors';
import toast, {Toaster} from 'react-hot-toast';
import CustomSelectRadio2 from '../../UI/custom-select-radio2/CustomSelectRadio2';

const Register = () => {
  const Location = useLocation();
  const distenationRoute = localStorage.getItem('distenationRoute') || '/'
  const navigate = useNavigate();
  const email: string = Location.state.email;
  const social: boolean | null = Location.state.social;
  const provider_id: string | null = Location.state.provider_id;
  const provider: string | null = Location.state.provider;
  const dispatch = useAppDispatch();
  const languages = useAppSelector(state => state.language.languages);

  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');
  const [gender, setGender] = useState<number>(1);
  const [langSelected, setLangSelected] = useState<Language[]>([]);
  const [passwordInput, setPasswordInput] = useState<string | number>('');
  const [showPassword, setShowPassword] = useState(false);
  const [refCode, setRefCode] = useState('');
  const [error, setError] = useState<{[key: string]: string}>();
  const[acceptTerms , setAcceptTerms ] = useState(false);

  useEffect(() => {
    dispatch(insertLanguages());
  }, []);

  const check = async () => {
    let errors: {[key: string]: string} = {};

    // let phoneCheck =
    //   /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    // if (
    //   phoneCheck.test(
    //     `${phone}${this.containZero(this.state.phone)}`,
    //   ) == false &&
    //   this.state.phone != ''
    // )
    //   errors.phone = I18n.t('wrongphone');

    if (name == '') errors.name = 'Name Invalid';

    if (passwordInput == '' && !social) errors.pass = 'Password Invalid';
    else if (String(passwordInput)?.length < 8 && !social)
      errors.pass = 'wrongpass';
    if (langSelected.length == 0) errors.lan = 'you must choose your languages';
    //if (!birthDate) errors.birthdate = 'Enter Birth Date';
    if (!acceptTerms) errors.acceptTerms = 'You must accept terms and conditions';

    setError(errors);
    if (Object.keys(errors).length === 0) {
      RegisterNewAccount();
    }
  };
  const RegisterNewAccount = async () => {
    let data: FormData = new FormData();
    data.append('phone', phone);
    data.append('username', name);
    data.append('birth_date', birthDate);
    // data.append('phone_code', '055');
    langSelected.map((i, index) => {
      data.append(`language_id[${index}]`, String(i?.id));
    });
    data.append('gender', String(gender));
    data.append('email', email);
    data.append('type', 'user');
    if (!social) {
      data.append('password', String(passwordInput));
    }
    if (social) {
      data.append('provider_id', String(provider_id));
      data.append('provider', String(provider));
    }
    // data.append('firebase_token', 'firebase_token');

    //refCode !== '' && data.append('referer_code', refCode);

    localStorage.hasOwnProperty("userRefCode") && data.append('referer_code', String(localStorage.getItem("userRefCode")) )
    let result = await RegisterAccount(data);
    console.log('result.response', result.response);

    if( result.response.errors?.referer_code ){
      toast.error(result.response.errors?.referer_code[0]);
    }

    if (result?.response?.access_token) {
      localStorage.setItem('logged', '1');
      localStorage.setItem('token', result.response.access_token);
      localStorage.setItem('name', result.response.user.username);
      localStorage.setItem('email', result.response.user.email);
      localStorage.setItem('avatar', result.response.user.avatar);
      localStorage.setItem('id', result.response.user.id);
      localStorage.setItem('isCons', result.response.user.is_consultant);
      localStorage.setItem('switch', '0');
      localStorage.setItem('Consid', result.response.user.consultation_info ? result.response.user.consultation_info.id : 0 );
      localStorage.setItem('feedBack', result.response.user.consultation_info ? JSON.stringify(result.response.user.consultation_info?.feedBack) : JSON.stringify([]) );
      localStorage.setItem('ratingStars', result.response.user.consultation_info ? JSON.stringify(result.response.user.consultation_info?.rating_stars) :  JSON.stringify([]) );
      localStorage.setItem('stars', result.response.user.consultation_info ? result.response.user.consultation_info?.ratings : 0 );

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      
      const type = urlParams.get('type')
      const modelId = urlParams.get('model_id')
      const invitationId = urlParams.get('invitation_id')
      const invitee_id = urlParams.get('invitee_id')
    
      if(  type && modelId ){
        if( type == 'Initiative' ){ 
          //navigate(`/register?type=Initiative&model_id=${modelId}&invitation_id=${invitationId}`, {state: {email: email}});
          window.location.href = `/user/trackings/${modelId}/details?is_level=0&type=${type}&model_id=${modelId}&invitation_id=${invitationId}${invitee_id ? `&invitee_id=${invitee_id}` : '' }`;
        }
        else if( type == 'InitiativePackage' ){
          window.location.href = `/user/initiatives/${modelId}/package/levels?discover=1&type=${type}&model_id=${modelId}&invitation_id=${invitationId}${invitee_id ? `&invitee_id=${invitee_id}` : '' }`;
        }else if( type == 'Event' ){
          navigate(`/event/${modelId}/details?type=Event&model_id=${modelId}`)
        }else if( type == 'tracking' ){
          navigate(`/user/trackings/${modelId}/details?is_level=0`)
        }else if( type == 'trackingPackage' ){
          navigate(`/user/trackings/${modelId}/package/levels?discover=1`)
        }else if( type == 'Course' ){
          navigate(`/courses/${modelId}`)
        }else if( type == 'Consultant' ){
          navigate(`/consultants/consultant/${modelId}`)
        }
        
        
      }
      else{
        if( type == 'InitiativesList' ){
          navigate('/user/initiatives/')
        }else if( type == 'FollowUpSystemsList' ){
          navigate('/user/trackings/list/')
        }else if( type == 'FollowUpSystemsList' ){
          navigate('/consultants/get-teachers')
        }else{
          window.location.href = distenationRoute;
        }
       
      }

      
    } else {
      if (result?.error?.response?.status == 400) {
        setError(result?.error?.response?.data?.msg);
      } else {
        let errors: {[key: string]: string} = {};
        if (Object.keys(result.response.errors).length != 0) {
          if (result.response.errors?.email) {
            errors.Email = result.response.errors.email[0];
          }
          if (result.response?.errors?.phone) {
            errors.phone = result.response.errors.phone[0];
          }
          if (result.response?.errors?.username) {
            console.log('pop');
            errors.name = result.response?.errors?.username[0];
          }
          if (result.response?.errors?.password)
            errors.pass = result.response?.errors?.password[0];
          if (result.response?.errors?.referer_code)
            errors.referer_code = result.response?.errors?.referer_code[0];
          setError(errors);
        }
      }
    }
  };
  const LangSelectedCheck = (i: number): boolean => {
    if (langSelected.find(item => item.id == i)) return true;
    else return false;
  };

  return (
    <div>
      <Container className={classes.FormContainer}>
        <Toaster position="top-right" />
        <Row className="d-flex justify-content-between">
          

          <Col md={7}  >
            <div className={classes.FormPadding}>
              <h3
                className={`fontWeight-bold ${classes.HeaderTitle} `}>
                Create Your Account
              </h3>

              <Row>
                <Col md="4">
                  <div className="mt-3 mb-3">
                    <label className="mb-2">
                      Name<span style={{color: 'red'}}>* </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control m-0 ${classes.FormInput}`}
                      required
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                  {error?.name && (
                    <p style={{color: colors.darkRed}}>{error.name}</p>
                  )}
                </Col>

                {/*
                <Col xs={{span: 12}} md={{span: 6}}>
                  <div className="mt-3 mb-3">
                    <label className="mb-2">Mobile Number</label>
                    <input
                      type="text"
                      className={`form-control m-0 ${classes.FormInput}`}
                      required
                      onChange={e => setPhone(e.target.value)}
                    />
                  </div>
                  {error?.phone && (
                    <p style={{color: colors.darkRed}}>{error.phone}</p>
                  )}
                </Col>
                */}

                {/*  
                <Col xs={{span: 12}} md={{span: 6}}>
                  <div className="mt-3 mb-3">
                    <label className="mb-2">
                      <span style={{color: 'red'}}>* </span>Birth Date
                    </label>
                    <input
                      type="date"
                      className={`form-control m-0 ${classes.FormInput}`}
                      placeholder="DD-mm-yyyy"
                      required
                      onChange={e => setBirthDate(e.target.value)}
                    />
                  </div>
                  {error?.birthdate && (
                    <p style={{color: colors.darkRed}}>{error.birthdate}</p>
                  )}
                </Col>
                */}

                

                <Col md="4">
                  <div className="mt-3 mb-3">
                    <label className="mb-2">
                      Languages you speak <span style={{color: 'red'}}>* </span>
                    </label>
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        color="red"
                        className={`form-control m-0 ${classes.Drop}`}></Dropdown.Toggle>

                      <Dropdown.Menu>
                        {languages.map(i => {
                          return (
                            <Dropdown.Item
                              onClick={() => {
                                if (
                                  langSelected.find(
                                    (item: any) => item.id == i.id,
                                  )
                                ) {
                                  
                                  setLangSelected(
                                    langSelected.filter(
                                      (item: any) => i.id != item.id,
                                    ),
                                  );
                                } else {
                                
                                  setLangSelected((prev: any[]) => [
                                    ...prev,
                                    i,
                                  ]);
                                }
                              }}
                              >
                              <Row className="d-flex justify-content-between">
                                <Col md={11}>
                                  <div> {i.name}</div>
                                </Col>
                                {LangSelectedCheck(i.id) && (
                                  <Col md={1}>
                                    <FaCheckCircle color="#FA5456" size={20} />
                                  </Col>
                                )}
                              </Row>
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="d-flex" style={{flexWrap: 'wrap'}}>
                      {langSelected.map(i => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              borderRadius: 10,
                              padding: 8,
                              marginBlock: 10,
                              width: 'fit-content',
                              margin: '10px 5px',
                              background: '#ffe519',
                            }}>
                            <Col>{i.name}</Col>
                            <Col
                              onClick={() => {
                                setLangSelected(
                                  langSelected.filter(items => items.id != i.id),
                                );
                              }}
                              style={{
                                alignItems: 'flex-end',
                                textAlign: 'right',
                                margin: '0 0 0 10px',
                              }}>
                              X
                            </Col>
                          </div>
                        );
                      })}
                    </div>
                    {error?.lan && (
                      <p style={{color: colors.darkRed}}>{error.lan}</p>
                    )}
                  </div>
                </Col>
                {!social && (
                  <Col md="4">
                    <div className="mt-3 mb-3">
                      <label className="mb-2">
                        Password<span style={{color: 'red'}}>* </span>
                      </label>
                      <div style={{position: 'relative'}}>
                      <img
                              src="carbon_password.svg"
                              alt="password"
                              className='carbon_password'
                            />  
                        <div
                          className={classes.togglePassword}
                          onClick={() => setShowPassword(!showPassword)}>
                          
                          {!showPassword ? (
                            <img
                              src="/icons/close.png"
                              alt="password"
                              width={25}
                            />
                          ) : (
                            <FaEye color="#08cad7" size={20} />
                          )}
                        </div>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          onChange={e => setPasswordInput(e.target.value)}
                          value={passwordInput}
                          className={`form-control m-0 px-5 ${classes.FormInput}`}
                          required
                          placeholder='*********'
                        />
                      </div>
                    </div>
                    {error?.pass && (
                      <p style={{color: colors.darkRed}}>{error.pass}</p>
                    )}
                  </Col>
                )}

                <Col xs={{span: 12}} md={{span: 12}}>
                  <div className="mt-3 mb-3">
                    <label className="mb-2">
                      Gender<span style={{color: 'red'}}>* </span>
                    </label>
                    <div className='d-md-flex gap-4'>
                      <div className='mb-1' >
                       

                        <CustomSelectRadio2
                          name="gender"
                          text="Male"
                          forId={'Male'}
                          disabled={false}
                          value={1}
                          onRadioChange={(value: string) =>
                           setGender(1)
                          }
                          checked={gender == 1}
                        />
                       
                      </div>
                      <div className='mb-1' >
                      <CustomSelectRadio2
                          name="gender"
                          text="Female"
                          forId={'Female'}
                          disabled={false}
                          value={2}
                          onRadioChange={(value: string) =>
                           setGender(2)
                          }
                          checked={gender == 2}
                        />
                      </div>
                      {/*
                      <div className='mb-1' >
                        <CustomRadio
                          text="Prefer not to disclose"
                          checked={gender == 3}
                          onClick={() => setGender(3)}
                        />
                      </div>
                      */}
                    </div>
                  </div>
                </Col>

                <Col xs={{span: 12}} md={{span: 12}}>
                  <div className="mt-3 mb-3">
                    <label className={classes.CheckBoxContainer}>
                      <span>
                        I accept the{' '}
                        <a
                          href="/terms-and-conditions"
                          target="_blanck"
                          className={classes.TermsAndConditions}>
                          Terms and conditions
                        </a>
                      </span>
                      <input type="checkbox" checked={acceptTerms} onChange={(event:any) => setAcceptTerms(event.target.value) } />
                      <span className={classes.Checkmark}></span>
                    </label>
                  </div>
                  {error?.acceptTerms && (
                    <p style={{color: colors.darkRed}}>{error.acceptTerms}</p>
                  )}
                </Col>

                {/*  
                <Col xs={{span: 12}} md={{span: 12}}>
                  <div className={`mt-4 mb-3 ${classes.InviteCode}`}>
                    <h5>Do you have invitation code</h5>
                    <input
                      type="text"
                      className={`form-control m-0 ${classes.FormInput}`}
                      placeholder="Invitation Code"
                      required
                      onChange={e => setRefCode(e.target.value)}
                    />
                    {error?.referer_code && (
                      <p style={{color: colors.darkRed}}>{error.referer_code}</p>
                    )}
                    <div className={`${classes.InviteHint}`}>
                      If you have an invitation code from a friend, please enter
                      it here, if you don't have it, you can ignore this step
                    </div>
                  </div>
                </Col>
                */}
                <Col xs={{span: 12}} md={{span: 12}}>
                  {/* <h5>{error}</h5> */}
                </Col>

                <div className="mt-4 mb-2">
                  <MainButton text="Register" onClick={check} />
                </div>
              </Row>

              {/* <div className="col-12 text-center">
                              <p className="mb-0 mt-3">
                                  <NavLink to="/contact-us" className={`text-primary font-weight-bold`} style={ {'textDecoration' : 'none'} }> Contact Us</NavLink>&nbsp;&nbsp;
                                  <NavLink to="/terms-and-conditions" className={`text-primary font-weight-bold`} style={ {'textDecoration' : 'none'} }> Terms And Conditions</NavLink>
                              </p>
                            </div> */}
            </div>
          </Col> 

          <Col
            md={5}
            style={{
              backgroundImage: 'url("/kogin-bg.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;